import React, {useState, useEffect} from 'react'
import {motion} from 'framer-motion'
import NavItem from './NavItem/NavItem'
import CmsItem from './CmsItem/CmsItem'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'
import CmsForm from './CmsForm/CmsForm'
import cmsStore from '../../../zustand/cms'

const Cms = () => {

  const [rollerWidth, setRollerWidth] = useState(0)
  const [rollerPosition, setRollerPosition] = useState(0)
  const [currNav, setCurrentNav] = useState('Zespół')
  const [isEditingOrder, setIsEditingOrder] = useState(false)
  const [isBackpanelOpen, setIsBackpanelOpen] = useState(false)
  const allCmsItems = cmsStore(state => state.allCmsItems)
  const sortedCmsItems = cmsStore(state => state.sortedCmsItems)
  const sortCmsItems = cmsStore(state => state.sortCmsItems)
  const setIndex = cmsStore(state => state.setIndex)
  const saveIndexes = cmsStore(state => state.saveIndexes)

  const handleSaveEdit = () => {
    saveIndexes()
    setIsBackpanelOpen(false)
    setIsEditingOrder(false)
  }

  useEffect(() => {
    let el = document.getElementById(currNav)
    setRollerWidth(el?.offsetWidth)
    setRollerPosition(el?.offsetLeft)
    setIsEditingOrder(false)
    sortCmsItems(currNav)
  }, [currNav])
  
  useEffect(() => {
    sortCmsItems(currNav)
  }, [allCmsItems, isEditingOrder])

  const handleDragEnd = (e) => {
    setIndex(e.source.index, e.destination.index)
  }
  

  return (
    <div id="CMS" class="w-full flex flex-col sm:py-6 px-2 md:px-12 ">
      <div id="cms_NavContainer" class="w-full h-full flex-wrap sm:flex-nowrap  flex justify-between items-center">
        <div id="cms_Nav" class="w-full sm:w-auto sm:max-w-[75%] h-12 rounded-2xl relative bg-gray-200">
            <div style={{zIndex: 3}}
            class="px-4 relative h-full flex  items-center justify-around">
              <NavItem text='Zespół' currNav={currNav} setCurrentNav={setCurrentNav}/>
              <NavItem text='Współprace' currNav={currNav} setCurrentNav={setCurrentNav}/>
              <NavItem text='Galeria' currNav={currNav} setCurrentNav={setCurrentNav}/>
              <NavItem text='Prasa' currNav={currNav} setCurrentNav={setCurrentNav}/>
              <NavItem text='Inne zdjęcia' currNav={currNav} setCurrentNav={setCurrentNav}/>
            </div>
            <motion.div style={{zIndex: 1}} animate={{width: `${rollerWidth}px`, left: `${rollerPosition}px`}} 
            class="bg-black absolute h-[80%] w-20 left-2 top-[10%] rounded-2xl">
            </motion.div>
          </div>
          <div class="w-full hidden sm:block mt-4 sm:mt-0 sm:w-auto bg-black rounded text-white cursor-pointer  py-2 px-4  text-center hover:bg-gray-800 transition"
              onClick={isEditingOrder ? handleSaveEdit : () => { setIsBackpanelOpen(true)}}>
            {isEditingOrder ? 'Zapisz' : 'Dodaj'}
          </div>
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId='lista' >
        {(provided) => (
          <div class="w-full flex flex-col mt-8 " {...provided.droppableProps} ref={provided.innerRef}>
              {sortedCmsItems?.map((item, index) => {return(
                <Draggable key={`item${index}`} draggableId={`item${index}`} index={index} isDragDisabled={!isEditingOrder}>
                  {(provided) => (
                    <div class="w-full h-16 mt-4"
                     {...provided.draggableProps} {...provided.dragHandleProps}  ref={provided.innerRef}>
                      <CmsItem item={item} isEditingOrder={isEditingOrder}/>
                    </div>
                  )}
                </Draggable>
              )})}
              {provided.placeholder}
          </div>
        )}
      </Droppable>
      </DragDropContext> 
      {currNav !== "Inne zdjęcia" && <div class="fixed bottom-4 right-5 flex items-center px-4 py-2 bg-white rounded-full">
        <p class="mr-4">Zmień kolejność</p>
        <motion.div onClick={() => {setIsEditingOrder(!isEditingOrder)}} class="w-12 h-6 rounded-2xl p-1 cursor-pointer overflow-hidden relative bg-gray-300">
           <motion.div initial={{x: '100%'}} animate={{x: isEditingOrder ? '100%' : 0}} transition={{type: 'linear', duration: 0.3, delay: 0}} 
           class="absolute top-[2.5%] h-[95%] aspect-square rounded-full bg-white" style={{zIndex: 5}}></motion.div>
           <motion.div initial={{x: '-10%'}} animate={{x: isEditingOrder ? '-10%' : '-110%'}} transition={{type: 'linear', duration: 0.3, delay: 0}} 
           class="absolute top-0 w-[120%] h-[120%] bg-black"></motion.div>
        </motion.div>
      </div>}
      <div class="w-full block sm:hidden mt-4 sm:mt-0 sm:w-auto bg-black rounded text-white cursor-pointer  py-2 px-4  text-center hover:bg-gray-800 transition"
              onClick={isEditingOrder ? handleSaveEdit : () => { setIsBackpanelOpen(true)}}>
            {isEditingOrder ? 'Zapisz' : 'Dodaj'}
      </div>
      {isBackpanelOpen && <div class="fixed w-screen h-screen left-0 top-0 flex justify-center items-center" style={{zIndex: 4}}>
        <motion.div onClick={() => setIsBackpanelOpen(false)} initial={{opacity: 0}} animate={{opacity: 1}} class="fixed left-0 top-0 w-full h-full cursor-pointer" 
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}></motion.div>
        <motion.div initial={{scale: 0.8}} animate={{scale: 1}} style={{zIndex: 5}}
        class="cursor-auto w-[90%] sm:w-2/3 md:w-1/2 lg:w-1/3 bg-gray-100 rounded p-6 flex flex-col items-center" >
            <CmsForm type={currNav} setIsBackpanelOpen={setIsBackpanelOpen}/>
        </motion.div>
      </div>}
  </div>
  )
}

export default Cms