import create from 'zustand'
import { devtools } from 'zustand/middleware'
import * as api from '../api/index'

const store = (set, get) => ({
    allCmsItems: [],
    sortedCmsItems: [], 
    addCmsItem: async (newItem) => {
        try{
            let formData = new FormData();

            await Object.entries(newItem).forEach(i => {
                if(i[0] === "name" || i[0] === "desc" || i[0] === "country"){
                    formData.append(i[0], JSON.stringify(i[1]))
                }else{
                    formData.append(i[0], i[1])
                }
            })


            await api.addCmsItem(formData)

            let result = await api.getCmsItems()
            set(state => ({allCmsItems: result.data.result}))
        }catch(err){
            console.log(err)
        }
    },
    getCmsItems: async () => {
        try{
            let result = await api.getCmsItems()
            set(state => ({allCmsItems: result.data.result}))
            
        }catch(err){
            console.log(err)
        }
    },
    deleteCmsItem: async (item) => {
        try{
            await api.deleteCmsItem(item)
            let result = await api.getCmsItems()
            set(state => ({allCmsItems: result.data.result}))
            
        }catch(err){
            console.log(err)
        }
    },
    updateCmsItem: async (id, item) => {
        try{
            let body = [id, item]
            await api.updateCmsItem(body)

            let result = await api.getCmsItems()
            set(state => ({allCmsItems: result.data.result}))
            
        }catch(err){
            console.log(err)
        }
    },
    sortCmsItems: async (type) => {
        try{
            let allItems = get().allCmsItems
            if(allItems?.length > 0 ){
                let filtered = await allItems.filter(i => i.type === type)
                let sorted = await filtered.sort((a,b) => a.order - b.order)
                set(state => ({sortedCmsItems: sorted}))
                }else{
                    set(state => ({sortedCmsItems: []})) 
                }
        }catch(err){
            console.log(err)
        }
    },
    setIndex: async (originIndex, newIndex) => {

        if(originIndex !== newIndex){
            originIndex++
            newIndex++
            let oldArr = await get().sortedCmsItems
            let newArr = await oldArr.map(i => {
                if((i.order < newIndex && i.order < originIndex) || (i.order > newIndex && i.order > originIndex)){
                    return i
                }else if(i.order === originIndex) {
                    return ({...i, order: newIndex})
                }else{
                    if(i.order >= newIndex && i.order < originIndex){
                        return ({...i, order: i.order + 1})
                    }else if(i.order <= newIndex && i.order > originIndex){
                        return ({...i, order: i.order - 1})
                    }
                }
                
            })
            
            set(state => ({sortedCmsItems: newArr.sort((a,b) => a.order - b.order)}))
        }

    }, 
    saveIndexes: async () => {
        try{
            let body = []
            await get().sortedCmsItems?.forEach(i => {
                body.push({id: i._id, order: i.order})
            });
            
            await api.updateCmsIndexes(body)

            let result = await api.getCmsItems()
            set(state => ({allCmsItems: result.data.result}))
        }catch(err){
            console.log(err)
        }
    },
})


const cmsStore = create(devtools(store))

export default cmsStore