import React, {useState, useEffect} from 'react'
import { faArrowRightFromBracket, faHouseChimney, faNewspaper, faChevronRight, faFileCircleCheck, faLocationDot, faBook, faPen,faLanguage } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NavBtn from './NavBtn/NavBtn'
import userStore from '../../zustand/user'
import { NavLink } from 'react-router-dom'
import {motion} from 'framer-motion'
import optionsStore from '../../zustand/options'

const Nav = () => {
    const logOut = userStore(state => state.logOut)
    const isNavOpen = optionsStore(state => state.isNavOpen)
    const setIsNavOpen = optionsStore(state => state.setIsNavOpen)
    const windowSize = optionsStore(state => state.windowSize)
    const leftPx = optionsStore(state => state.leftPx)
    const isBackpanelOpen = optionsStore(state => state.isBackpanelOpen)
    const isDevMode = optionsStore(state => state.isDevMode)

    return (
        <motion.div id="nav " class="h-full border-solid border-r-2 bg-gray-100 border-gray-200 flex flex-col absolute xl:static"
        style={{width: '300px', zIndex: 2}} initial={{left: `calc(-300px + ${leftPx}px)`}} animate={{left: `calc(-300px + ${leftPx}px)`}}>
            <div id="logoContaienr" class="w-full h-28 flex justify-center items-center border-solid border-b-2  border-gray-200">
                <p style={{fontFamily: 'Sedgwick Ave Display', color: '#E07566'}} class="text-6xl">WAP</p>
            </div>
            <div id="navContainer" class="w-full grow p-8">
                <NavLink to="/Zarzadzanie">
                    {({isActive}) => (
                        <NavBtn active={isActive} onClick={setIsNavOpen} icon={faHouseChimney} text={'Zarządzanie'}/>
                    )}
                </NavLink>
                <NavLink to="/Tlumaczenie">
                    {({isActive}) => (
                        <NavBtn active={isActive} onClick={setIsNavOpen} icon={faLanguage} text={'Tłumaczenie'}/>
                    )}
                </NavLink>
                <NavLink to="/Cms">
                    {({isActive}) => (
                        <NavBtn active={isActive} onClick={setIsNavOpen} icon={faPen} text={'Treści na stronie'}/>
                    )}
                </NavLink>
                <NavLink to="/Aktualnosci">
                    {({isActive}) => (
                        <NavBtn active={isActive} onClick={setIsNavOpen} icon={faNewspaper} text={'Aktualności'}/>
                    )}
                </NavLink>
                <NavLink to="/Regulaminy">
                    {({isActive}) => (
                        <NavBtn active={isActive} onClick={setIsNavOpen} icon={faFileCircleCheck} text={'Regulaminy'}/>
                    )}
                </NavLink>
                <NavBtn onClick={() => {setIsNavOpen(); window.open('https://sites.google.com/corignis.org/wiki-fundacja/home', '_blank')}} icon={faBook} text={'Wiki'}/>
            </div>
            <div id="confContainer" class="w-full mb-4 p-8">
                <NavBtn onClick={logOut} icon={faArrowRightFromBracket} text={'Wyloguj się'} />
            </div>
            {!isBackpanelOpen && <div id="chevronContainer" class='xl:hidden block absolute top-1/2 h-5 w-5 text-gray-300 hover:text-gray-800 cursor-pointer' 
            onClick={setIsNavOpen}  style={{left: '102%', zIndex: 3}}>
               <motion.div animate={{rotate: isNavOpen ? '180deg' : '0deg'}}>
                 <FontAwesomeIcon class="text-xl " icon={faChevronRight}/>
                </motion.div>
            </div>}
        </motion.div>
    )
}

export default Nav