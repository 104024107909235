import React, {useState, useEffect} from 'react'
import newsStore from '../../../zustand/news'
import {motion, AnimatePresence} from 'framer-motion'
import NewsItem from './NewsItem/NewsItem'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'
import Select from 'react-select';
import translatingStore from '../../../zustand/translating'
import { orderChanged } from '../../../animations/animations'

const News = () => {
const sortedNews = newsStore(state => state.sortedNews)
const allNews = newsStore(state => state.allNews)
const sortNews = newsStore(state => state.sortNews)
const setIndex = newsStore(state => state.setIndex)
const saveIndexes = newsStore(state => state.saveIndexes)
const [language, setNewLanguage] = useState({value: null, label: `Wybierz język`})
const [languagesOptinos, setLanguagesOptinos] = useState({value: null, label: `Wybierz język`})
const languages = translatingStore(state => state.languages)
const [isOrderChanged, setIsOrderChanged] = useState(false)


useEffect(() => {
  let arr = []
  languages?.forEach(i => {
      arr.push({value: i?._id, label: i?.name})
  })
  setLanguagesOptinos(arr)
  setNewLanguage({value: languages[0]?._id, label: languages[0]?.name})
}, [languages])

useEffect(() => {
  sortNews(language)
  setIsOrderChanged(false)
}, [language, allNews])

const handleDragEnd = (e) => {
  setIndex(e.source.index, e.destination.index)
  setIsOrderChanged(true)
}



  return (
    <div id="News" class="w-full h-full sm:py-6 px-2 md:px-12">
      <div class="w-full flex justify-between items-center">
        <h2 class="font-bold text-2xl">Lista aktualności</h2>
        <Select options={languagesOptinos} value={language}
                onChange={setNewLanguage} styles={{
                  container: (baseStyles, state) => ({
                    ...baseStyles,
                    width: '200px',
                    margin: '1rem 0',
                    cursor: 'pointer',
                    zIndex: 3,
                  }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    padding: '1rem 0',
                    background: 'rgb(229 231 235)',
                    border: 'none',
                    outline: 'none',
                    boxShadow: 'none',
                    cursor: 'pointer',
                  }),
                }}/></div>
      <div class="w-full flex flex-col mt-8">
        <NewsItem newItem language={language} />
        <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId='newsList'>
          {(provided) => (
            <div class="w-full flex flex-col my-8" {...provided.droppableProps} ref={provided.innerRef}>
                {sortedNews?.map((item, index) => {return(
                      <NewsItem item={item} index={index}/>
                )})}
                {provided.placeholder}
            </div>
          )}
        </Droppable>
        </DragDropContext>
      </div>
      <AnimatePresence>{isOrderChanged && <motion.div class="fixed left-0 w-full flex justify-center z-[3]"
      variants={orderChanged} initial="start" animate="end" exit="start">
          <div class="w-3/5 p-4 bg-gray-300 rounded flex justify-between items-center">
            <p class="text-lg">Czy chcesz zapisać zmiany w kolejności elementów?</p>
            <div class="flex"> 
              <div class="bg-gray-400 mr-8 rounded text-white cursor-pointer  py-1 px-4  text-center hover:bg-gray-500 transition"
              onClick={() => {setIsOrderChanged(false); sortNews(language)}}>
                Cofnij
              </div>
              <div class="bg-black  rounded text-white cursor-pointer  py-1 px-4  text-center hover:bg-gray-800 transition"
              onClick={() => {setIsOrderChanged(false); saveIndexes()}}>
                Zapisz
              </div>
            </div>
          </div>
      </motion.div>}</AnimatePresence>
    </div>
  )
}

export default News