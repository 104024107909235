export const notifContainer = {
    start: {
        opacity: 0,
    },
    end: {
        opacity: 1, 
        transition: {
            staggerChildren: 0.5,
        },
    }
}
export const notifItem = {
    start: {
        x: 15,
        y: 15,
        opacity: 0.5,
    },
    end: {
        x: 0,
        y: 0,
        opacity: 15,
    }
}
export const searchItem = {
    start: {
        y: 15,
        opacity: 0.5,
    },
    end: {
        y: 0,
        opacity: 15,
    }
}
export const textPopup = {
    start: {
        scale: 0.75,
    },
    end: {
        scale: 1,
    }
}
export const deleteForm = {
    start: {
        y: '-20%',
        opacity: 0,
        transition: {
            duration: 0.1,
        }
    },
    end: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.1,
        }
    }
}

export const editBtn = {
    start: {
        y: '-20%',
        opacity: 0,
        transition: {
            duration: 0.2,
        }
    },
    end: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.2,
            delay: 0.3,
        }
    },
}
export const editEndBtn = {
    start: {
        y: '20%',
        opacity: 0,
        transition: {
            duration: 0.2,
        }
    },
    end: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.2,
            delay: 0.3,
        }
    },
}
export const newsItem = {
    start: {
        height: 0,
        transition: {
            duration: 0.3,
        }
    },
    end: {
        height: 'auto',
        transition: {
            duration: 0.3,
        }
    },
}
export const newsItemContent = {
    start: {
        y: '-100%',
        opacity: 0,
        transition: {
            duration: 0.3,
        }
    },
    end: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.3,
        }
    },
}
export const newsItemArrows = {
    start: {
        opacity: 0,
        transition: {
            duration: 0.3,
        }
    },
    end: {
        opacity: 1,
        transition: {
            duration: 0.3,
        }
    },
}
export const orderChanged = {
    start: {
        opacity: 0,
        bottom: '-50px',
        transition: {
            duration: 0.3,
        }
    },
    end: {
        opacity: 1,
        bottom: '10px',
        transition: {
            duration: 0.3,
        }
    },
}
export const cmsItem = {
    start: {
        opacity: 0,
        y: '20%',
        transition: {
            duration: 0.3,
        }
    },
    end: {
        opacity: 1,
        y: '0',
        transition: {
            duration: 0.3,
        }
    },
}
