import React, {useState, useEffect} from 'react'
import Nav from '../../components/Nav/Nav'
import Search from '../../components/Search/Top'
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import News from './News/News'
import Home from './Home/Home';
import Cms from './Cms/Cms'
import Ignitorium from './Ignitorium/Ignitorium'
import Notifications from './Notifications/Notifications'
import optionsStore from '../../zustand/options';
import dashboardStore from '../../zustand/dashboard';
import notificationsStore from '../../zustand/notifications.js'
import searchStore from '../../zustand/search';
import {motion} from 'framer-motion' 
import {useSwipeable} from 'react-swipeable'
import Translating from './Translating/Translating';
import translatingStore from '../../zustand/translating'
import newsStore from '../../zustand/news';
import cmsStore from '../../zustand/cms';
import documentsStore from '../../zustand/documents';
import Documents from './Documents/Documents';

const MainView = () => {
  const isNavOpen = optionsStore(state => state.isNavOpen)
  const setIsNavOpen = optionsStore(state => state.setIsNavOpen)
  const checkWindowSize = optionsStore(state => state.checkWindowSize)
  const windowSize = optionsStore(state => state.windowSize)
  const getNotifications = notificationsStore(state => state.getNotifications)
  const getSearchLinks = searchStore(state => state.getSearchLinks)
  const setLeftPx = optionsStore(state => state.setLeftPx)
  const isDevMode = optionsStore(state => state.isDevMode)
  const isBackpanelOpen = optionsStore(state => state.isBackpanelOpen)
  const getIsSiteBlocked = dashboardStore(state => state.getIsSiteBlocked)
  const getLanguages = translatingStore(state => state.getLanguages)
  const getNews = newsStore(state => state.getNews)
  const getCmsItems = cmsStore(state => state.getCmsItems)
  const getDocsItems = documentsStore(state => state.getDocsItems)

  useEffect(() => {
    getNotifications()
    getSearchLinks()
    checkWindowSize()
    getIsSiteBlocked()
    getLanguages()
    getNews()
    getCmsItems()
    getDocsItems()
    window.addEventListener('resize', checkWindowSize)
  }, [])

  const swipes = useSwipeable({
    onSwiping: (eventData) => handleSwipe(eventData),
    onSwipedRight: (eventData) => {
      if(!isBackpanelOpen){
        if(isNavOpen === false && eventData.deltaX > 50){
          setIsNavOpen()
        }
      }
    },
    onSwipedLeft: (eventData) => {
      if(!isBackpanelOpen){
        if(eventData.deltaX < -50 && isNavOpen === true){
          setIsNavOpen()
        }
      }
    },
  });

  const handleSwipe = (eventData) => {
    if(!isBackpanelOpen){
      if(eventData.deltaX > 50 && isNavOpen === false){

        if(eventData.deltaX <= 300){
        setLeftPx(eventData.deltaX)
      }
      }else if(eventData.deltaX < -50 && isNavOpen === true){
        if(eventData.deltaX >= -300){
        setLeftPx(300 + eventData.deltaX)
        }

      }
    }
  }

  
  return (
    <BrowserRouter>
    <div {...swipes} id="mainWrapper" class="w-screen h-screen flex-row flex bg-inherit relative overflow-hidden">
      <Nav />
      <motion.div id="content" class="h-full overflow-x-hidden flex flex-col absolute" transition={{type: 'linear'}}  initial={{width: windowSize > 1280? `calc(100vw - 300px)` : '100vw', left:  windowSize > 1280 ? '300px' : `0px`}}
      animate={{width: windowSize > 1280? `calc(100vw - 300px)` : '100vw', left:  windowSize > 1280 ? '300px' : `0px`}}>
        <Search />
        <wrapper class='w-full' style={{height: 'calc(100% - 7rem)'}}>
        <Routes>
          <Route path="*" element={<Navigate to={`/Zarzadzanie`} /> } />
          <Route path="/Zarzadzanie" element={<Home />} />
          <Route path="/Aktualnosci" element={<News />} />
          <Route path="/Cms" element={<Cms />} />
          <Route path="/Powiadomienia" element={<Notifications />} />
          <Route path="/Tlumaczenie" element={<Translating />} />
          <Route path="/Regulaminy" element={<Documents />} />
        </Routes>
        </wrapper>
      </motion.div>
    </div>
    </BrowserRouter>

  )
}

export default MainView