const data = [
    {
        title: 'Odpowiedz na maila',
        mail: "abce@domena.pl",
        moreInfo: 'odpowiedział na maila',
        link: '/Dashboard',
        date: new Date('12/03/2022'),
        new: false,
    }, 
    {
        title: 'Odpowiedz na maila',
        mail: "abce@domena.pl",
        moreInfo: 'odpowiedział na maila',
        link: '/Dashboard',
        date: new Date('2022-03-22T03:24:00'),
        new: true,
    }, 
    {
        title: 'zapytanie o ignitorium',
        mail: "abce@domena.pl",
        moreInfo: 'odpowiedział na maila',
        link: '/Ignitorium',
        date: new Date('03/03/2022'),
        new: false,
    }, 
    {
        title: 'Odpowiedz na maila',
        mail: "abce@domena.pl",
        moreInfo: 'odpowiedział na maila',
        link: '/Dashboard',
        date: new Date('12/03/2022'),
        new: true,
    }, 
    {
        title: 'zapytanie o ignitoriuma',
        mail: "abce@domena.pl",
        moreInfo: 'odpowiedział na maila',
        link: '/Ignitorium',
        date: new Date('12/03/2022'),
        new: true,
    }, 
    {
        title: 'zapytanie o ignitorium',
        mail: "abce@domena.pl",
        moreInfo: 'odpowiedział na maila',
        link: '/Ignitorium',
        date: new Date('12/03/2022'),
        new: true,
    }, 
    {
        title: 'zapytanie o ignitorium',
        mail: "abce@domena.pl",
        moreInfo: 'odpowiedział na maila',
        link: '/Ignitorium',
        date: new Date('12/03/2022'),
        new: false,
    }, 
    {
        title: 'zapytanie o ignitorium',
        mail: "abce@domena.pl",
        moreInfo: 'odpowiedział na maila',
        link: '/Ignitorium',
        date: new Date('12/03/2022'),
        new: true,
    }, 
    {
        title: 'Odpowiedz na maila',
        mail: "abce@domena.pl",
        moreInfo: 'odpowiedział na maila',
        link: '/Dashboard',
        date: new Date('12/03/2022'),
        new: false,
    }, 
    {
        title: 'Odpowiedz na maila',
        mail: "abce@domena.pl",
        moreInfo: 'odpowiedział na maila',
        link: '/Dashboard',
        date: new Date('03/22/2022'),
        new: true,
    }, 
    {
        title: 'zapytanie o ignitorium',
        mail: "abce@domena.pl",
        moreInfo: 'odpowiedział na maila',
        link: '/Ignitorium',
        date: new Date('03/03/2022'),
        new: false,
    }, 
    {
        title: 'Odpowiedz na maila',
        mail: "abce@domena.pl",
        moreInfo: 'odpowiedział na maila',
        link: '/Dashboard',
        date: new Date('12/03/2022'),
        new: true,
    }, 
    {
        title: 'zapytanie o ignitoriuma',
        mail: "abce@domena.pl",
        moreInfo: 'odpowiedział na maila',
        link: '/Ignitorium',
        date: new Date('12/03/2022'),
        new: true,
    }, 
    {
        title: 'zapytanie o ignitorium',
        mail: "abce@domena.pl",
        moreInfo: 'odpowiedział na maila asffssfaaa',
        link: '/Ignitorium',
        date: new Date('12/03/2022'),
        new: true,
    }, 
    {
        title: 'zapytanie o ignitorium',
        mail: "abce@domena.pl",
        moreInfo: 'odpowiedział na maila',
        link: '/Ignitorium',
        date: new Date('12/03/2022'),
        new: false,
    }, 
    {
        title: 'zapytanie o ignitorium',
        mail: "abce@domena.pl",
        moreInfo: 'odpowiedział na maila asfffs',
        link: '/Ignitorium',
        date: new Date('12/03/2022'),
        new: true,
    }, 
    {
        title: 'Odpowiedz na maila',
        mail: "abce@domena.pl",
        moreInfo: 'odpowiedział na maila',
        link: '/Dashboard',
        date: new Date('12/03/2022'),
        new: false,
    }, 
    {
        title: 'Odpowiedz na maila',
        mail: "abce@domena.pl",
        moreInfo: 'odpowiedział na maila fsfasfaf',
        link: '/Dashboard',
        date: new Date('03/22/2022'),
        new: true,
    }, 
    {
        title: 'zapytanie o ignitorium',
        mail: "abce@domena.pl",
        moreInfo: 'odpowiedział na maila',
        link: '/Ignitorium',
        date: new Date('03/03/2022'),
        new: false,
    }, 
    {
        title: 'Odpowiedz na maila',
        mail: "abce@domena.pl",
        moreInfo: 'odpowiedział na maila affsafsfas asfasf',
        link: '/Dashboard',
        date: new Date('12/03/2022'),
        new: true,
    }, 
    {
        title: 'zapytanie o ignitoriuma',
        mail: "abce@domena.pl",
        moreInfo: 'odpowiedział na maila',
        link: '/Ignitorium',
        date: new Date('12/03/2022'),
        new: true,
    }, 
    {
        title: 'zapytanie o ignitorium',
        mail: "abce@domena.pl",
        moreInfo: 'odpowiedział na maila',
        link: '/Ignitorium',
        date: new Date('12/03/2022'),
        new: true,
    }, 
    {
        title: 'zapytanie o ignitorium',
        mail: "abce@domena.pl",
        moreInfo: 'odpowiedział na maila',
        link: '/Ignitorium',
        date: new Date('12/03/2022'),
        new: false,
    }, 
    {
        title: 'zapytanie o ignitorium',
        mail: "abce@domena.pl",
        moreInfo: 'odpowiedział na mailaassfafasasffas',
        link: '/Ignitorium',
        date: new Date('12/03/2022'),
        new: true,
    }, 
]

export default data