import React, {useState, useEffect} from 'react'
import optionsStore from '../../../zustand/options'
import {motion} from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX, faCheckDouble } from '@fortawesome/free-solid-svg-icons'
import { useLocation, useNavigate } from 'react-router-dom';
import notificationsStore from '../../../zustand/notifications'
import NotifItem from '../../../views/MainView/Notifications/NotifItem/NotifItem'

const NotifWindow = ({setIsNotifOpen}) => {
  const setIsBackpanelOpen = optionsStore(state => state.setIsBackpanelOpen)
  const newNotifications = notificationsStore(state => state.newNotifications)
  const handleBackpanelClick = () => {
    setIsNotifOpen(false)
    setIsBackpanelOpen()
  }
  const [currNotif, setCurrNotif] = useState('all')
  const navigate = useNavigate()

  const handleRedirect = () => {
    navigate('/Powiadomienia')
    handleBackpanelClick()
  }

  return (
    <div id="NotifWindow" class="fixed w-screen	h-screen left-0 top-0" style={{zIndex: 4}}>
      <motion.div onClick={handleBackpanelClick} id='NorifBackPanel' initial={{opacity: 0}} animate={{opacity: 1}}
      class="absolute left-0 top-0 w-full h-full cursor-pointer" style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}></motion.div>
      <div  class='transition cursor-auto flex flex-col  absolute p-4 top-28 right-4 sm:right-14 w-11/12 sm:w-1/2 md:w-2/5 xl:w-1/4 bg-gray-100 rounded'
       style={{minHeight: '300px', maxHeight: '80vh', zIndex: 5, }}>
        <div id="NotifWindow_Headline" class="w-full h-10 flex items-center justify-between">
          <h2 class="text-2xl font-bold">{newNotifications.length > 0 ? newNotifications.length : '0'} Powiadomień</h2>
          <div onClick={handleBackpanelClick} class="h-full flex items-center justify-center aspect-square rounded
          cursor-pointer bg-gray-200 border-2 border-gray-200 border-solid	" >
            <FontAwesomeIcon icon={faX} />
          </div>
        </div>
        <div id="NotifWindow_Nav" class="w-full flex items-center justify-between my-4">
          <span>
          <span onClick={() => setCurrNotif('all')} style={{borderBottom: currNotif === 'all' && 'solid 2px  rgb(31 41 55)'}} 
          class="text-sm mr-2 cursor-pointer border-gray-800 border-solid hover:border-b-2">Wszystkie</span>
          <span onClick={() => setCurrNotif('other')} style={{borderBottom: currNotif === 'other' && 'solid 2px  rgb(31 41 55)'}}
          class="text-sm mr-2 cursor-pointer border-gray-800 border-solid hover:border-b-2">Inne</span>
          <span onClick={() => setCurrNotif('igni')} style={{borderBottom: currNotif === 'igni' && 'solid 2px  rgb(31 41 55)'}}
           class="text-sm mr-2 cursor-pointer border-gray-800 border-solid hover:border-b-2">Ignitorium</span>
          </span>
          <span class="text-sm mr-2 cursor-pointer hover:font-bold"> <FontAwesomeIcon icon={faCheckDouble} /> Oznacz jako przeczytane</span>
        </div>
        {currNotif === 'all' && <>
          {newNotifications.length > 0 ? <div id="NotifWindow_Content" class="flex-grow w-full flex flex-col overflow-y-auto">
            {newNotifications.map((item, i) => i + 1 <= 20 && (
              <NotifItem item={item} setIsNotifOpen={setIsNotifOpen}/>
            ))} </div>: 
            <div id="NotifWindow_ContentEmpty" class="flex-grow w-full flex justify-center items-center">
            <h3 class=" text-gray-400">Nie ma nowych powiadomień</h3>
          </div>}
        </>}
        {currNotif === 'other' && <>
          {newNotifications.filter(i => i.link === '/Dashboard').length > 0 ? <div id="NotifWindow_Content" class="flex-grow w-full flex flex-col overflow-y-auto">
           {newNotifications.filter(i => i.link === '/Dashboard').map((item, i) => i + 1 <= 10 && (
              <NotifItem item={item} setIsNotifOpen={setIsNotifOpen}/>
            ))} </div> : 
            <div id="NotifWindow_ContentEmpty" class="flex-grow w-full flex justify-center items-center">
            <h3 class=" text-gray-400">Nie ma nowych innych powiadomień</h3>
          </div>}
        </>}
        {currNotif === 'igni' && <>
          {newNotifications.filter(i => i.link === '/Ignitorium').length > 0 ? <div id="NotifWindow_Content" class="flex-grow w-full flex flex-col overflow-y-auto">
            {newNotifications.filter(i => i.link === '/Ignitorium').map((item, i) => i + 1 <= 10 && (
              <NotifItem item={item} setIsNotifOpen={setIsNotifOpen}/>
            ))} </div>: 
            <div id="NotifWindow_ContentEmpty" class="flex-grow w-full flex justify-center items-center">
            <h3 class=" text-gray-400">Nie ma nowych powiadomień z Ignitorium</h3>
          </div>}
        </>}
        <div onClick={handleRedirect} class="bg-black mt-4 text-white cursor-pointer w-3/4 mx-auto p-2 rounded text-center hover:bg-gray-800 transition">Zobacz wszystkie</div>
      </div>
    </div>
  )
}

export default NotifWindow