import React, {useState, useEffect} from 'react'
import notificationsStore from '../../../zustand/notifications'
import {motion, AnimatePresence} from 'framer-motion'
import { notifContainer } from '../../../animations/animations'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import NotifItem from './NotifItem/NotifItem'
import optionsStore from '../../../zustand/options'

const Notifications = () => {
const notifications = notificationsStore(state => state.notifications)
const newNotifications = notificationsStore(state => state.newNotifications)
const windowSize = optionsStore(state => state.windowSize)

const [isHover, setIsHover] = useState(false)
const [notifType, setNotifType] = useState('Wszystkie powiadomienia')
const [howManyShow, setHowManyShow] = useState(1)

const handleGetMore = () => {
  switch (notifType) {
    case 'Wszystkie powiadomienia': 
      howManyShow * 6 < newNotifications.length  ? setHowManyShow(howManyShow + 1) : setHowManyShow(1)
      break;
    case 'Ignitorium': 
      howManyShow * 6 < newNotifications.filter(i => i.link === '/Ignitorium').length  ? setHowManyShow(howManyShow + 1) : setHowManyShow(1)
      break;
    case 'Inne': 
      howManyShow * 6 < newNotifications.filter(i => i.link === '/Dashboard').length  ? setHowManyShow(howManyShow + 1) : setHowManyShow(1)
      break;
    case 'Archiwum': 
      howManyShow * 6 < notifications.length  ? setHowManyShow(howManyShow + 1) : setHowManyShow(1)
      break;
  }
}

useEffect(() => {
  setHowManyShow(1)
}, [notifType])

  return (
    <div id="Notifications" class="w-full h-full sm:py-6 px-2 md:px-12">
      <div class="w-full h-12 flex justify-between items-center">
        <div onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} class="w-full sm:w-3/5 relative">
          <div class="text-2xl flex justify-start items-center">
            <span class="mr-2">{notifType}</span>
            <motion.span animate={{rotate: isHover ? '180deg' : '0deg'}}><FontAwesomeIcon icon={faChevronDown}/></motion.span>
          </div>
          <AnimatePresence>
            {isHover && <motion.ul onClick={() => setIsHover(false)} class='w-full  absolute bg-gray-100' 
            initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} style={{zIndex: 2}}>
              {notifType !== 'Wszystkie powiadomienia' && <li onClick={() => setNotifType('Wszystkie powiadomienia')} 
              class="p-2 cursor-pointer hover:bg-gray-200 hover:font-bold">Wszystkie powiadomienia</li>}
              {notifType !== 'Inne' && <li onClick={() => setNotifType('Inne')} 
              class="p-2 cursor-pointer hover:bg-gray-200 hover:font-bold">Inne</li>}
              {notifType !== 'Ignitorium' && <li onClick={() => setNotifType('Ignitorium')} 
              class="p-2 cursor-pointer hover:bg-gray-200 hover:font-bold">Ignitorium</li>}
              {notifType !== 'Archiwum' && <li onClick={() => setNotifType('Archiwum')} 
              class="p-2 cursor-pointer hover:bg-gray-200 hover:font-bold">Archiwum</li>}
            </motion.ul>}
          </AnimatePresence>
        </div>
        {windowSize > 640 && <div class="bg-black text-white cursor-pointer px-4  p-2 rounded text-center hover:bg-gray-800 transition">
          {notifType === 'Archiwum' ?  'Wyczyść archiwum' : 'Oznacz jako przeczytane'}
        </div>}
      </div>
      <motion.div variants={notifContainer} initial="start" animate="end" id='notifLists' class="w-full sm:mt-4 overflow-y-auto" style={{height: 'calc(100% - 8.5rem'}}>
        {notifType === 'Wszystkie powiadomienia' && <>
          {newNotifications.length > 0 ? 
            <>{newNotifications.map((item, i) => i + 1 <= howManyShow * 6 && (
                <NotifItem notifType={notifType} item={item} key={`NotifItem${i}`}/>

            ))}
            {newNotifications.length > 6 &&
            <div onClick={handleGetMore} class="mt-4 bg-black text-white cursor-pointer px-4  
            p-2 rounded text-center hover:bg-gray-800 transition">{howManyShow * 6 < newNotifications.length ? 'Pokaż więcej' : 'Pokaż mniej'}</div>}
            </> :
            <div class="w-full h-full flex justify-center items-center ">
              <h1 class="text-6xl text-gray-400">Nie ma nowych powiadomień</h1> 
            </div>  
          }
        </>}
        {notifType === 'Ignitorium' && <>
          {newNotifications.filter(i => i.link === '/Ignitorium').length > 0 ? 
            <>{newNotifications.filter(i => i.link === '/Ignitorium').map((item, i) => i + 1 <= howManyShow * 6 && (
                <NotifItem notifType={notifType}  item={item} key={`NotifItem${i}`}/>

            ))}
            {newNotifications.filter(i => i.link === '/Ignitorium').length > 6 &&
            <div onClick={handleGetMore} class="mt-4 bg-black text-white cursor-pointer px-4  
            p-2 rounded text-center hover:bg-gray-800 transition">
              {howManyShow * 6 < newNotifications.filter(i => i.link === '/Ignitorium').length ? 'Pokaż więcej' : 'Pokaż mniej'}
            </div>}
            </> :
            <div class="w-full h-full flex justify-center items-center ">
              <h1 class="text-6xl text-gray-400">Nie ma nowych powiadomień z Ignitorium</h1> 
            </div>  
          }
        </>}
        {notifType === 'Inne' && <>
          {newNotifications.filter(i => i.link === '/Dashboard').length > 0 ? 
            <>{newNotifications.filter(i => i.link === '/Dashboard').map((item, i) => i + 1 <= howManyShow * 6 && (
                <NotifItem notifType={notifType}  item={item} key={`NotifItem${i}`}/> 
            ))}
            {newNotifications.filter(i => i.link === '/Dashboard').length > 6 &&
              <div onClick={handleGetMore} class="mt-4 bg-black text-white cursor-pointer px-4  
            p-2 rounded text-center hover:bg-gray-800 transition">
              {howManyShow * 6 < newNotifications.filter(i => i.link === '/Dashboard').length  ? 'Pokaż więcej' : 'Pokaż mniej'}
            </div>}
            </> :
            <div class="w-full h-full flex justify-center items-center ">
              <h1 class="text-6xl text-gray-400">Nie ma nowych innych powiadomień </h1> 
            </div>  
          }
        </>}
        {notifType === 'Archiwum' && <>
          {notifications.length > 0 ? 
            <>{notifications.map((item, i) => i + 1 <= howManyShow * 6 && (
                <NotifItem notifType={notifType}  item={item} key={`NotifItem${i}`}/>

            ))}
            {notifications.length > 6 &&
            <div onClick={handleGetMore} class="mt-4 bg-black text-white cursor-pointer px-4  
            p-2 rounded text-center hover:bg-gray-800 transition">{howManyShow * 6 < notifications.length  ? 'Pokaż więcej' : 'Pokaż mniej'}</div>}
            </> :
            <div class="w-full h-full flex justify-center items-center ">
              <h1 class="text-6xl text-gray-400">Archiwum jest puste</h1> 
            </div>  
          }
        </>}
      </motion.div> 
      {windowSize < 640 && 
      <div class="w-full h-16 flex items-center">
        <div class="bg-black text-white cursor-pointer w-full  p-2 rounded text-center hover:bg-gray-800 transition">
          {notifType === 'Archiwum' ?  'Wyszyść archiwum' : 'Oznacz jako przeczytane'}
        </div> 
      </div>}
    </div>
  )
}

export default Notifications