import React, {useState, useEffect} from 'react'
import {motion} from 'framer-motion'
import {deleteForm} from '../../../animations/animations'
import translatingStore from '../../../zustand/translating'
import LanguageItem from './LanguageItem/LanguageItem'
import SettingsItem from './SettingsItem/SettingsItem'
import TranslateItem from './TranslateItem/TranslateItem'
import Select from 'react-select';
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import optionsStore from '../../../zustand/options'


const Translating = () => {
  const [translateOptions1, setTranslateOptions1] = useState([])
  const [translateOptions2, setTranslateOptions2] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])
  const [filterOptions, setFilterOptions] = useState([])
  const [newCategoryOptions, setNewCategoryOptions] = useState([])
  const [translateFrom, setTranslateFrom] = useState({value: null, label: `Wybierz język orginalny`})
  const [translateTo, setTranslateTo] = useState({value: null, label: `Wybierz język do tłumaczenia`})
  const [category, setCategory] = useState({value: null, label: `Wybierz kategorie`})
  const [newCategory, setNewCategory] = useState({value: null, label: `Wybierz lub wpisz kategorie`})
  const [filter, setFilter] = useState({value: null, label: `Wyszukaj literał`})
  const languages = translatingStore(state => state.languages)
  const getLanguages = translatingStore(state => state.getLanguages)
  const getTxtItems = translatingStore(state => state.getTxtItems)
  const addTxtItem = translatingStore(state => state.addTxtItem)
  const txtItems = translatingStore(state => state.txtItems)
  const windowSize = optionsStore(state => state.windowSize)
  const [itemsFiltered, setItemsFiltered] = useState([])
  const [isOptionsSet, setIsOptionsSet] = useState(false)
  const [isBackpanelOpen, setIsBackpanelOpen] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const [newItem, setNewItem] = useState({
    itemId: "",
    itemTxt: "",
    itemCategory: "",
  })

  useEffect(() => {
    getLanguages()
    getTxtItems()
  }, [])

  useEffect(() => {
    let arr = []
    languages?.forEach(i => {
        arr.push({value: i.name, label: `Tłumacz z: ${i.name}`})
    })
    setTranslateOptions1(arr)

    let arr2 = []
    languages?.forEach(i => {
        arr2.push({value: i.name, label: `Tłumacz na: ${i.name}`})
    })
    setTranslateOptions2(arr2)
}, [languages])

  const handleCategoryAdd = (e) => {
    if(e.key === "Enter"){
      setNewCategoryOptions([...newCategoryOptions, {value: e.target.value, label: e.target.value}])
      setNewCategory({value: e.target.value, label: e.target.value})
    }
  }

  useEffect(() => {
    setNewItem({...newItem, itemCategory: newCategory.value})
  }, [newCategory])

  const handleSubmit = (e) => {
    e.preventDefault()
    
    if(!newItem.itemCategory){
      setErrorMsg("Wybierz kategorie")
    }else{
      addTxtItem(newItem, setIsBackpanelOpen, setErrorMsg)
      setNewItem({
        itemId: "",
        itemTxt: "",
        itemCategory: "",
      })
      setNewCategory({value: null, label: `Wybierz lub wpisz kategorie`})
    }
  }

  useEffect(() => {
    setErrorMsg("")
  }, [newItem])

  const prevEnter = (e) => {
    if(e.keyCode === 13){
      e.preventDefault()
    }
  }

  useEffect(() => {
    let createCategoryOptions = async () => {
      let arr = []
      let result = []
      await txtItems.forEach(i => {
        if(!arr.includes(i.itemCategory)){
          arr.push(i.itemCategory)
          result.push({value: i.itemCategory, label: i.itemCategory})
        }
      })
      await setCategoryOptions(result)
      await setNewCategoryOptions(result)
    }
    createCategoryOptions()
    
  }, [txtItems])

  useEffect(() => {
    if(category.value === null){
      let arr = txtItems.map(i =>  ({value: i.itemTxt, label: i.itemTxt}))
      setFilterOptions(arr)
    }else{
      let arr = txtItems.filter(i => i.itemCategory === category.value)
      let newArr = arr.map(i =>  ({value: i.itemTxt, label: i.itemTxt}))
      setFilterOptions(newArr)
    }
  }, [category])

  useEffect(() => {
    const setFilteredItems = async () => {
      await setIsOptionsSet(false)
      if(translateTo.value && translateFrom.value){
        setIsOptionsSet(true)
        setItemsFiltered(txtItems) 
        if(category.value){
          setItemsFiltered(txtItems?.filter(i => i.itemCategory === category.value ? i : null))
        } 
        if(filter.value){
          setItemsFiltered(txtItems?.filter(i => i.itemTxt === filter.value ? i : null))
        }
      }
    }
    setFilteredItems()
  }, [category, filter, translateTo, translateFrom, txtItems])

  return (
    <div id="Translating" class="w-full flex flex-col sm:py-6 px-4 md:px-12">
      <div id="Translating_Content" class="w-full ">
          <h2 class="font-bold text-2xl mt-4">Wspierane języki</h2>
          <div class="my-6 w-full flex flex-col sm:flex-row flex-wrap justify-start">
            <LanguageItem />
            <SettingsItem />
            {languages?.map(i => <LanguageItem item={i}/>)}
          </div>
          <h2 class="font-bold text-2xl my-4">Tłumaczenie literałów</h2>
          <div class="w-full flex flex-col lg:flex-row">
            <div class="w-full lg:w-2/3 grow order-2 lg:order-1">
              {!isOptionsSet ?
                <div class="w-full h-full flex justify-center items-center text-gray-400 text-2xl">
                  <h2>Ustaw najpierw filtry tłumaczeń</h2>
                </div>:
                <div class="w-full flex flex-col">
                  {itemsFiltered?.map(item => <TranslateItem item={item} translateFrom={translateFrom} translateTo={translateTo} languagesGlobal={languages} />)}
                </div>
              }
            </div>
            <div class="w-full lg:w-1/3 flex flex-col sm:flex-row lg:flex-col flex-wrap items-center order-1 lg:order-2">
              <Select options={translateOptions1} value={translateFrom} 
                onChange={setTranslateFrom} styles={{
                  container: (baseStyles, state) => ({
                    ...baseStyles,
                    width: windowSize > 1024 ? '75%' : '100%',
                    margin: '1rem 0',
                    cursor: 'pointer',
                  }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    padding: '1rem 0',
                    background: 'rgb(229 231 235)',
                    border: 'none',
                    outline: 'none',
                    boxShadow: 'none',
                    cursor: 'pointer',
                    "&:hover": {
                      background: 'rgb(243 244 246)',
                      boxShadow: '-1px 14px 24px -21px rgba(66, 68, 90, 1)',
                    },
                  }),
                }}/>
              <Select options={translateOptions2} value={translateTo}
                onChange={setTranslateTo} styles={{
                  container: (baseStyles, state) => ({
                    ...baseStyles,
                    width: windowSize > 1024 ? '75%' : '100%',
                    margin: '1rem 0',
                    cursor: 'pointer',
                  }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    padding: '1rem 0',
                    background: 'rgb(229 231 235)',
                    border: 'none',
                    outline: 'none',
                    boxShadow: 'none',
                    cursor: 'pointer',
                    "&:hover": {
                      background: 'rgb(243 244 246)',
                      boxShadow: '-1px 14px 24px -21px rgba(66, 68, 90, 1)',
                    },
                  }),
                }}/>
              <Select options={categoryOptions} value={category}  
                onChange={setCategory} styles={{
                  container: (baseStyles, state) => ({
                    ...baseStyles,
                    width: windowSize > 1024 ? '75%' : '100%',
                    margin: '1rem 0',
                    cursor: 'pointer',
                  }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    padding: '1rem 0',
                    background: 'rgb(229 231 235)',
                    border: 'none',
                    outline: 'none',
                    boxShadow: 'none',
                    cursor: 'pointer',
                    "&:hover": {
                      background: 'rgb(243 244 246)',
                      boxShadow: '-1px 14px 24px -21px rgba(66, 68, 90, 1)',
                    },
                  }),
                }}/>
              <Select options={filterOptions} value={filter}  
                onChange={setFilter} styles={{
                  container: (baseStyles, state) => ({
                    ...baseStyles,
                    width: windowSize > 1024 ? '75%' : '100%',
                    margin: '1rem 0',
                    cursor: 'pointer',
                  }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    padding: '1rem 0',
                    background: 'rgb(229 231 235)',
                    border: 'none',
                    outline: 'none',
                    boxShadow: 'none',
                    cursor: 'pointer',
                    "&:hover": {
                      background: 'rgb(243 244 246)',
                      boxShadow: '-1px 14px 24px -21px rgba(66, 68, 90, 1)',
                    },
                  }),
                }}/>
                <div class="p-4 w-full lg:w-3/4 my-4 rounded bg-gray-200 hover:bg-gray-100 hover:shadow-lg
                transition duration-300 cursor-pointer flex items-center" onClick={() => setIsBackpanelOpen(true)}>
                    <FontAwesomeIcon icon={faPlus} class="h-8 mr-4"/>
                    <span>Dodaj nowy literał</span>
                </div>
            </div>
          </div>
      </div>
      {isBackpanelOpen && <div onSubmit={handleSubmit} class="fixed w-screen h-screen left-0 top-0 flex justify-center items-center" style={{zIndex: 4}}>
            <motion.div onClick={() => setIsBackpanelOpen(false)} initial={{opacity: 0}} animate={{opacity: 1}} class="fixed left-0 top-0 w-full h-full cursor-pointer" 
            style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}></motion.div>
            <motion.form initial={{scale: 0.8}} animate={{scale: 1}} class="cursor-auto w-[90%] sm:w-2/3 md:w-1/2 lg:w-1/3 bg-gray-100 rounded p-6 flex flex-col items-center" style={{zIndex: 5}}>
            <h2 class="w-full text-center font-bold text-xl">Dodaj nowy literał:</h2>
            <input required class="w-full lg:w-3/4 my-4 cursor-pointer bg-gray-200 outline-none py-6 rounded px-2 placeholder-black"  placeholder="Wpisz indywidualny identyfikator"
            value={newItem.itemId} onChange={(e) => setNewItem({...newItem, itemId: e.target.value.replace(/\s+/g, '-')})} type="text" maxLength={40} />
            <Select options={newCategoryOptions} value={newCategory} onKeyDown={(e) => handleCategoryAdd(e)} noOptionsMessage={() => (
            <div >Zatwierdź enterem żeby dodać kategorie</div>
            )}
                onChange={setNewCategory}  styles={{
                  container: (baseStyles, state) => ({
                    ...baseStyles,
                    width: windowSize > 1024 ? '75%' : '100%',
                    margin: '1rem 0',
                    cursor: 'pointer',
                  }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    padding: '1rem 0',
                    background: 'rgb(229 231 235)',
                    border: 'none',
                    outline: 'none',
                    boxShadow: 'none',
                    cursor: 'pointer',
                  }),
                }}/>
            <textarea required class="w-full lg:w-3/4 max-h-[30vh] my-4 cursor-pointer bg-gray-200 outline-none py-6 rounded px-2 placeholder-black"  placeholder="Wpisz domyślną treść literału"
            value={newItem.itemTxt} onChange={(e) => setNewItem({...newItem, itemTxt: e.target.value})} onKeyDown={prevEnter} type="text" maxLength={400} />
            <button type='submit' class="w-full lg:w-3/4 text-center bg-black text-white cursor-pointer p-2 rounded hover:bg-gray-800 transition">Dodaj literał</button>
            <div class="w-full my-2 h-4 flex justify-center items-center text-red-400">
                {errorMsg && <motion.p variants={deleteForm} initial="start" animate="end">{errorMsg}</motion.p>}
            </div>
            </motion.form>
      </div>}
    </div>
  )
}

export default Translating