import React, {useState, useEffect} from 'react'
import FileBase64 from 'react-file-base64';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {motion} from 'framer-motion'
import { deleteForm } from '../../../../../animations/animations'
import { EditorState, convertFromRaw, convertToRaw, createEmpty } from 'draft-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as fontIcons from '@fortawesome/free-solid-svg-icons'

const NewsItemForm = ({formValues, setFormValues, isImgOk1, isImgOk2, setIsImgOk1, setIsImgOk2}) => {
    
    const [addIconInput, setAddIconInput] = useState("")
    const [editorReactState, setEditorReactState] = useState(null)

    const handleAddIcon = () => {
        if(addIconInput.length > 0 && formValues.promoteValue.icons.length < 5 && typeof(fontIcons[addIconInput]) !== "undefined"){
            setFormValues({...formValues, promoteValue: {...formValues.promoteValue, icons: [...formValues.promoteValue.icons, {icon: addIconInput, text: "Treść"}]}})
        }
    }

    const handleEditIcons = async (e, i, index) => {
        if(e.target.value.length > 0){
            let newArr = [...formValues.promoteValue.icons]
            await newArr.splice(index, 1, {icon: i.icon, text: e.target.value})
            setFormValues({...formValues, promoteValue: {...formValues.promoteValue, icons: newArr}})
        }
    }
    const handleDeleteIcons = async (index) => {
        let newArr = [...formValues.promoteValue.icons]
        await newArr.splice(index, 1)
        setFormValues({...formValues, promoteValue: {...formValues.promoteValue, icons: newArr}})
    }

    const prevEnter = (e) => {
        if(e.keyCode === 13){
          e.preventDefault()
        }
      }

     const imgCheck = (e, type) => {
        if(type === "main"){
            setFormValues({...formValues, img: e.target.value})
            
            let img = new Image()
            img.src = e.target.value
            img.onload = () => {
                setIsImgOk1(true)
            }
            img.onerror = () => {
                setIsImgOk1(false)
            }
        }else if(type === "blog"){
            setFormValues({...formValues, typeValue: {...formValues.typeValue, blogImg: e.target.value}})

            let img = new Image()
            img.src = e.target.value
            img.onload = () => {
                setIsImgOk2(true)
            }
            img.onerror = () => {
                setIsImgOk2(false)
            }
        }
    }
     
    
    const handleEditorChange = async (editorState) => {
        let blogContent = await editorState.getCurrentContent()
        let convertedBlogContent = await convertToRaw(blogContent)
        setEditorReactState(editorState)
        setFormValues({...formValues, typeValue: {...formValues.typeValue, blogConverted: JSON.stringify(convertedBlogContent)}})
    }

    const initializeEditor = async () => {
        try{
            if(Object.keys(formValues.typeValue.blogConverted)?.length > 0){
                console.log(formValues.typeValue.blogConverted)
                let newState = EditorState.createWithContent(convertFromRaw(JSON.parse(formValues.typeValue.blogConverted)))
                setEditorReactState(newState)
            }else{
                let newState = EditorState.createEmpty()
                setEditorReactState(newState)
                let blogContent = await newState.getCurrentContent()
                let convertedBlogContent = await convertToRaw(blogContent)
                setFormValues({...formValues, typeValue: {...formValues.typeValue, blogConverted: JSON.stringify(convertedBlogContent)}})
            }
        }catch(err){
            console.log(err)
        }
    }

    useEffect(() => {
        initializeEditor()
    }, [])

    return (
        <div class="w-full md:w-2/3 flex flex-col items-center">
            <div class="w-full flex flex-wrap md:flex-nowrap justify-between items-center text-xl mt-4">
                <div class="w-full md:w-2/5 flex justify-between items-center text-xl">
                    <p class="mr-2">Tytuł:</p>
                    <input type="text" placeholder="Tytuł" maxLength={50} required value={formValues.title} onChange={(e) => setFormValues({...formValues, title: e.target.value})}
                    class="text-xl outline-none bg-transparent border-b-2 flex-grow border-solid border-black"  />
                </div>
                <div class="w-full mt-4 md:mt-0 md:w-2/5 flex justify-between items-center text-md">
                    <span class="mr-2" style={{color: isImgOk1 ? 'lime' : 'red'}}><FontAwesomeIcon icon={isImgOk1 ? fontIcons.faCheck : fontIcons.faExclamation}/></span>
                    <p class="text-xl mr-2">Zdjęcie:</p>
                    <input type="url" placeholder="Link do zdjęcia" maxLength={250} required value={formValues.img} onChange={(e) => imgCheck(e, "main")}
                    class="text-xl outline-none bg-transparent border-b-2 flex-grow border-solid border-black"  />
                </div>
            </div>
            <div class="w-full flex flex-col items-start text-xl mt-8">
                <div class="w-full flex justify-between items-center">
                    <p>Krótki opis:</p>
                    <div class="w-3/5 sm:1/3 md:w-2/5 flex justify-between items-center">
                        <p>Czy ma się rozwijać?</p>
                        <motion.div onClick={() => {setFormValues({...formValues, expandable: !formValues.expandable})}} class="w-12 h-6 rounded-2xl p-1 cursor-pointer overflow-hidden relative bg-gray-300">
                            <motion.div initial={{x: '100%'}} animate={{x: formValues.expandable ? '100%' : 0}} transition={{type: 'linear', duration: 0.3, delay: 0}} 
                            class="absolute top-[2.5%] h-[95%] aspect-square rounded-full bg-white" style={{zIndex: 5}}></motion.div>
                            <motion.div initial={{x: '-10%'}} animate={{x: formValues.expandable ? '-10%' : '-110%'}} transition={{type: 'linear', duration: 0.3, delay: 0}} 
                            class="absolute top-0 w-[120%] h-[120%] bg-black"></motion.div>
                        </motion.div>
                    </div>
                </div>
                <textarea  placeholder="Krótki opis" maxLength={450} required onKeyDown={prevEnter} value={formValues.description} 
                onChange={(e) => setFormValues({...formValues, description: e.target.value})}
                class="text-xl w-full h-32 resize-none outline-none bg-transparent border-b-2 flex-grow border-solid border-black mt-2"  />
            </div>
            <div class="w-full flex flex-wrap md:flex-nowrap justify-between items-center text-xl mt-8">
                <p>Panel ma zawierać dodatkowo:</p>
                <div required class="w-full md:w-3/5 mt-4 md:mt-0 flex justify-between items-center">
                    <label class="cursor-pointer" onClick={() => setFormValues({...formValues, promote: "nothing"})}>
                        <input checked={formValues.promote === "nothing"} type="radio" /> nic
                    </label>
                    <label class="cursor-pointer" onClick={() => setFormValues({...formValues, promote: "text"})}>
                        <input checked={formValues.promote === "text"} type="radio" /> tekst
                    </label>
                    <label class="cursor-pointer" onClick={() => setFormValues({...formValues, promote: "counter"})}>
                        <input checked={formValues.promote === "counter"} type="radio" /> odliczanie
                    </label>
                    {/* <label class="cursor-pointer" onClick={() => setFormValues({...formValues, promote: "fundraising"})}>
                        <input checked={formValues.promote === "fundraising"} type="radio" /> zbiórkę
                    </label> */}
                    <label class="cursor-pointer" onClick={() => setFormValues({...formValues, promote: "icons"})}>
                        <input checked={formValues.promote === "icons"} type="radio" /> ikony
                    </label>
                </div>
            </div>
            {formValues.promote === "nothing" && 
                <div class="w-3/5  flex justify-around items-center text-xl mt-8">
                    <p class="mr-2 text-center">Panel nie będzie wyświetlał dodatkowych informacji</p>
                </div>
            }
            {formValues.promote === "text" && 
                <div class="w-2/5  flex justify-around items-center text-xl mt-8">
                    <p class="mr-2">Tekst:</p>
                    <input type="text" placeholder="Tekst" maxLength={40} required value={formValues.promoteValue.text}
                     onChange={(e) => setFormValues({...formValues, promoteValue: {...formValues.promoteValue, text: e.target.value}})}
                    class="text-xl outline-none bg-transparent border-b-2 flex-grow border-solid border-black"  />
                </div>
            }
            {formValues.promote === "counter" && 
                <div class="w-[90%] flex-wrap md:flex-nowrap  flex justify-between items-center text-xl mt-8">
                    <div class="w-full md:w-[30%] mt-4 md:mt-0 flex items-center">
                        <p class="mr-2">Tekst przed:</p>
                        <input type="text" placeholder="Tekst przed" maxLength={10} required value={formValues.promoteValue.counter.before}
                        onChange={(e) => setFormValues({...formValues, promoteValue: {...formValues.promoteValue, counter: {...formValues.promoteValue.counter, before: e.target.value}}})}
                        class="text-xl w-[10%] outline-none bg-transparent border-b-2 flex-grow border-solid border-black"  />
                    </div>
                    <div class="w-full md:w-[30%] mt-4 md:mt-0 flex items-center"> 
                        <p class="mr-2">Liczba:</p>
                        <input type="number" placeholder="Tekst" required value={formValues.promoteValue.counter.value} min={0}
                        onChange={(e) => setFormValues({...formValues, promoteValue: {...formValues.promoteValue, counter: {...formValues.promoteValue.counter, value: e.target.value}}})}
                        class="text-xl w-[10%] outline-none bg-transparent border-b-2 flex-grow border-solid border-black"  />
                    </div>
                    <div class="w-full md:w-[30%] mt-4 md:mt-0 flex items-center">
                        <p class="mr-2">Tekst po:</p>
                        <input type="text" placeholder="Tekst" maxLength={10} required value={formValues.promoteValue.counter.after}
                        onChange={(e) => setFormValues({...formValues, promoteValue: {...formValues.promoteValue, counter: {...formValues.promoteValue.counter, after: e.target.value}}})}
                        class="text-xl w-[10%] outline-none bg-transparent border-b-2 flex-grow border-solid border-black"  />
                    </div>
                </div>
            }
            {formValues.promote === "fundraising" && 
                <div class="w-full md:w-2/5  flex justify-around items-center text-xl mt-8">
                    <p class="mr-2">Link do zbiórki:</p>
                    <input type="link" placeholder="Link do zbiórki" required value={formValues.promoteValue.text}
                     onChange={(e) => setFormValues({...formValues, promoteValue: {...formValues.promoteValue, text: e.target.value}})}
                    class="text-xl outline-none bg-transparent border-b-2 flex-grow border-solid border-black"  />
                </div>
            }
            {formValues.promote === "icons" && 
                <div class="w-[95%] flex justify-between items-center te
                xt-xl mt-8 flex-wrap md:flex-nowrap">
                     <div class="w-full md:w-1/4 flex items-center flex-wrap rounded ">
                        <input value={addIconInput} onChange={(e) => {setAddIconInput(e.target.value)}} class="h-8 w-3/4 outline-none rounded px-1" type="text" placeholder="Ikona" />
                        <div class="bg-black w-1/4 rounded h-8 text-white cursor-pointer  py-1 px-2  text-center
                        hover:bg-gray-800 transition" onClick={handleAddIcon}>Dodaj</div>
                        <div class="text-center text-xs text-gray-500 mt-1">Wejdź <a target="_blank" class="underline" href="https://fontawesome.com/search?o=r&m=free&s=solid">tu</a> 
                        {' wybierz ikone w zakładce React wybierz sekcje Individual Import i skopiuj wartość w icon={} przykładowo faHouse'}</div>
                     </div>
                    <div class="w-full md:w-3/4 mt-4 md:mt-0 flex justify-around items-center">
                        {formValues.promoteValue.icons.map((i, index) => {
                            return( 
                                <div class="max-w-[20%] h-full flex flex-col text-2xl">
                                    <FontAwesomeIcon icon={fontIcons[i.icon]}/>
                                    <input maxLength={20} type="text" class="outline-none bg-transparent text-sm text-center" value={i.text} 
                                    onChange={(e) => handleEditIcons(e, i, index)}/>
                                    <span onClick={() => handleDeleteIcons(index)} class="text-xs text-center text-red-700 cursor-pointer">{'[Usuń]'}</span>
                                </div>
                            )
                        })}
                    </div>
                </div>
            }
            <div class="flex-wrap md:flex-nowrap w-full flex justify-between items-center text-xl mt-8">
                <p>Aktualność ma być:</p>
                <div required class="w-full md:w-3/5 mt-4 md:mt-0 flex justify-between items-center">
                    <label class="cursor-pointer" onClick={() => setFormValues({...formValues, type: "static"})}>
                        <input checked={formValues.type === "static"} type="radio" /> statyczna
                    </label>
                    <label class="cursor-pointer" onClick={() => setFormValues({...formValues, type: "link"})}>
                        <input checked={formValues.type === "link"} type="radio" /> linkiem
                    </label>
                    <label class="cursor-pointer" onClick={() => setFormValues({...formValues, type: "blog"})}>
                        <input checked={formValues.type === "blog"} type="radio" /> wpisem blogowym
                    </label>
                </div>
            </div>
            {formValues.type === "link" && 
                <div class="w-full md:w-2/5  flex justify-around items-center text-xl mt-8">
                    <p class="mr-2">Link:</p>
                    <input type="link" placeholder="Link" required value={formValues.typeValue.link}
                     onChange={(e) => setFormValues({...formValues, typeValue: {...formValues.typeValue, link: e.target.value}})}
                    class="text-xl outline-none bg-transparent border-b-2 flex-grow border-solid border-black"  />
                </div>
            }
            {formValues.type === "blog" && 
                <div class="w-[95%] flex flex-col text-xl my-8">
                    <div class="w-full flex justify-between items-center text-md mb-4">
                        <p class="text-xl mr-2"><span class="mr-2" style={{color: isImgOk2 ? 'lime' : 'red'}}><FontAwesomeIcon icon={isImgOk2 ? fontIcons.faCheck : fontIcons.faExclamation}/></span>Zdjęcie w tle:</p>
                        <input type="url" placeholder="Link do zdjęcia" maxLength={250} required value={formValues.typeValue.blogImg} onChange={(e) => imgCheck(e, "blog")}
                        class="text-xl outline-none bg-transparent border-b-2 flex-grow border-solid border-black"  />
                    </div>
                    {editorReactState && <Editor 
                        editorState={editorReactState}
                        toolbarClassName="h-1/4"
                        wrapperClassName="bg-white h-96 w-full rounded"
                        editorClassName="bg-white h-3/4 p-2"
                        onEditorStateChange={handleEditorChange}
                        toolbar={{
                            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'colorPicker', 'image', 'link', 'list', 'textAlign', 'history'],
                            inline: {
                                options: ['bold', 'italic', 'underline', 'strikethrough'],
                            },
                        }}
                    />}
                </div>
            }
        </div>
    )
}

export default NewsItemForm