import create from 'zustand'
import * as api from '../api/index'
import axios from 'axios'
import { devtools } from 'zustand/middleware'



const store = (set, get) => ({
    isLogged: false,
    name: '',
    avatar: '',
    email: '',
    logInUser: async (token) => {
        try{
            let googleData = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })

            let {picture, given_name, email} = googleData.data

            let result = await api.verifyIsAdmin([email])
            
            set((state) => ({isLogged: true, name: given_name, avatar: picture, email: email}))
            
            let date = new Date()
            date.setDate(date.getDate() + 1);
            document.cookie = `userToken=${token}; expires=${date}; path=/`
        }catch(err){
            set((state) => ({isLogged: false, name: "", avatar: "", email: ""}))
        }
    },
    checkUser: async (next) => {
        let cookies = document.cookie.split('; ');
        let cookiesArr = {};
        for (let i = 0; i < cookies.length; i++) {
            let cur = cookies[i].split('=');
            cookiesArr[cur[0]] = cur[1];
        }
        let token = cookiesArr.userToken
        try{
            let result = await axios.get(`https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${token}`)
            if(result.status === 200){
                // set((state) => ({isLogged: true}))
                let logInUser = get().logInUser
                logInUser(token)
                next()
                
            }else{
                document.cookie = "userToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                set((state) => ({isLogged: false, name: "", avatar: "", email: ""}))
            }
        }catch (err){
            document.cookie = "userToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            set((state) => ({isLogged: false, name: "", avatar: "", email: ""}))
        }
        
    },
    logOut: async () => {
        document.cookie = "userToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        set((state) => ({isLogged: false, name: "", avatar: "", email: ""}))
    },
    
})


const userStore = create(devtools(store))

export default userStore