import create from 'zustand'
import { devtools } from 'zustand/middleware'
import * as api from '../api/index'

const store = (set, get) => ({
    languages: [],
    languagesSettings: [],
    txtItems: [],
    getLanguages: async () => {
        try{
            let result =  await api.getLanguages()
            set(state => ({languages: result.data.result}))
        }catch(err){
            console.log(err)
        }
    },
    addLanguage: async (newItem, setIsBackpanelOpen, setErrorMsg) => {
        try{
            await api.addLanguage(newItem)
            setIsBackpanelOpen(false)
            let result =  await api.getLanguages()
            set(state => ({languages: result.data.result}))
        }catch(err){
            setErrorMsg('Coś poszło nie tak')
        }
    },
    saveLanguages: async (newItem, itemID, setIsBackpanelOpen, setErrorMsg) => {
        try{
            let body = [newItem, itemID]
            await api.saveLanguage(body)
            let result =  await api.getLanguages()
            set(state => ({languages: result.data.result}))
            let settingsResult =  await api.getLanguagesSettings()
            set(state => ({languagesSettings: settingsResult.data.result}))
            setIsBackpanelOpen(false)
        }catch(err){
            setErrorMsg('Coś poszło nie tak')
        }

    },
    deleteLanguage: async (itemID, setIsBackpanelOpen, setDelLangError) => {
        try{
            let body = [itemID]
            await api.deleteLanguage(body)
            let result =  await api.getLanguages()
            set(state => ({languages: result.data.result}))
            let settingsResult =  await api.getLanguagesSettings()
            set(state => ({languagesSettings: settingsResult.data.result}))
            setIsBackpanelOpen(false)
        }catch(err){
            setDelLangError('Coś poszło nie tak')
        }
    },
    getLanguagesSettings: async () => {
        try{
            let result =  await api.getLanguagesSettings()
            set(state => ({languagesSettings: result.data.result}))
        }catch(err){
            console.log(err)
        }
    },
    changeLanguagesSettings: async (newValue, item, setIsBackpanelOpen) => {
        try{
            console.log(item._id)
            let body = [get().languages.filter(i => i.name === newValue)[0]._id, item._id]
            await api.changeLanguagesSettings(body)
            let result =  await api.getLanguagesSettings()
            set(state => ({languagesSettings: result.data.result}))
        }catch(err){
            console.log(err)
        }
    },
    getTxtItems: async () => {
        try{
            let result = await api.getTxtItems()
            set(state => ({txtItems: result.data.result}))
        }catch(err){
            console.log(err)

        }
    },
    addTxtItem: async (newItem, setIsBackpanelOpen, setErrorMsg) => {
        try{
            await api.addTextItem(newItem)
            let result = await api.getTxtItems()
            set(state => ({txtItems: result.data.result}))
            setIsBackpanelOpen(false)
        }catch(err){
            setErrorMsg('Coś poszło nie tak')
            console.log(err)
        }
    },
    deleteTxtItem: async (item, setDelFormError, setIsBackpanelOpen, getTxtItems) => {
        try{
            await api.deleteTextItem(item)
            await getTxtItems()
            await setIsBackpanelOpen(false)
        }catch(err){
            setDelFormError('Coś poszło nie tak')
            console.log(err)
        }
    },
    updateTxtItem: async (item, translateValue, langId, getTxtItems) => {
        try{
            if(typeof(translateValue) === "undefined"){
                translateValue = ""
            }
            let body = [item._id, translateValue, langId]
            await api.updateTextItem(body)
        }catch(err){
            console.log(err)
        }
    },
})


const translatingStore = create(devtools(store))

export default translatingStore