import React from 'react'
import './index.css'
import Login from './views/Login/Login'
import MainView from './views/MainView/MainView'
import userStore from './zustand/user'
import { GoogleOAuthProvider } from '@react-oauth/google';


const App = () => {
    const isLogged = userStore(state => state.isLogged)
    return (
        <GoogleOAuthProvider clientId="248795579882-aj5sggrrhe17j02d9rn534imkbkdtfei.apps.googleusercontent.com">
        <wrapper class="w-screen h-screen bg-gray-100">
            {isLogged ? <MainView /> : <Login />}
        </wrapper>
        </GoogleOAuthProvider >
    )
}

export default App