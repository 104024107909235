import React, {useState, useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faBell } from '@fortawesome/free-solid-svg-icons'
import userStore from '../../zustand/user';
import notificationsStore from '../../zustand/notifications'
import optionsStore from '../../zustand/options'
import NotifWindow from './NotifWindow/NotifWindow'
import SearchWindow from './SearchWindow/SearchWindow'
import {motion} from 'framer-motion'

const Top = () => {
  const location = useLocation()
  const [currLocation, setCurrLocation] = useState('Dashboard')
  const [notifCount, setNotifCount] = useState(0)
  const avatar = userStore(state => state.avatar)
  const newNotifications = notificationsStore(state => state.newNotifications)
  const [isNotifOpen, setIsNotifOpen] = useState(false)
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const windowSize = optionsStore(state => state.windowSize)
  const isDevMode = optionsStore(state => state.isDevMode)
  const setIsBackpanelOpen = optionsStore(state => state.setIsBackpanelOpen)
  const isBackpanelOpen = optionsStore(state => state.isBackpanelOpen)
  const navigate = useNavigate()

  useEffect(() => {
    setCurrLocation(location.pathname.slice(1))
  }, [location])

  useEffect(()=> {
    if(newNotifications.length < 10){
      setNotifCount(newNotifications.length)
    }else{
      setNotifCount('9+')
    }
  }, [newNotifications])

  const handleNotifClick = () => {
    if(windowSize < 640){
      navigate('/Notifications')
    }else{
      setIsNotifOpen(true)
      setIsBackpanelOpen()
    }
  }
  const handleSearchClick = () => {
    setIsSearchOpen(true)
    setIsBackpanelOpen()
  }

  return (
    <div id="top" class="w-full h-28 py-8 px-1 sm:px-8 items-center flex flex-row justify-between border-solid border-b-2  border-gray-200 ">
      <h2 class="hidden md:block w-1/6 font-bold text-3xl">{currLocation}</h2>
      <div onClick={handleSearchClick} id='searchBtn' class="w-5/6 sm:w-4/6 md:w-2/5 h-full cursor-pointer rounded p-3 border-solid border-2
       border-gray-300 hover:border-gray-900 transition text-gray-400 hover:text-gray-500 flex flex-row justify-start items-center"  >
        <FontAwesomeIcon icon={faSearch}/> <span class="ml-3">Wyszukaj...</span>
      </div>
      {isSearchOpen && <SearchWindow setIsSearchOpen={setIsSearchOpen} />}
      <div id='buttons'  class="aspect-square sm:aspect-auto sm:w-auto h-full flex flex-row justify-end items-center">
        {isDevMode &&<div onClick={handleNotifClick} id='notif' class='mr-3 aspect-square h-4/5 rounded-full bg-gray-300 flex 
        justify-center items-center cursor-pointer'>
          <FontAwesomeIcon  icon={faBell}/>
          <motion.div class="h-5 flex justify-center items-center translate-y-3/4 translate-x-3/4 rounded-full aspect-square
           absolute bg-red-500 text-sm"  >{notifCount}</motion.div>
         </div>}
         <div id='profile' class='hidden sm:flex mr-3 aspect-square h-4/5 rounded-full bg-gray-300  justify-center items-center overflow-hidden'>
            <img src={avatar} />
         </div>
      </div>
      {isNotifOpen && <NotifWindow setIsNotifOpen={setIsNotifOpen} />}
    </div>
  )
}

export default Top