import create from 'zustand'
import { devtools } from 'zustand/middleware'
import * as api from '../api/index'

const store = (set, get) => ({
    allDocsItems: [],
    sortedDocsItems: [], 
    addDocsItem: async (newItem) => {
        try{
            await api.addDocsItem(newItem)

            let result = await api.getDocsItems()
            set(state => ({allDocsItems: result.data.result}))
        }catch(err){
            console.log(err)
        }
    },
    getDocsItems: async () => {
        try{
            let result = await api.getDocsItems()
            set(state => ({allDocsItems: result.data.result}))
            
        }catch(err){
            console.log(err)
        }
    },
    deleteDocsItem: async (item) => {
        try{
            await api.deleteDocsItem(item)
            let result = await api.getDocsItems()
            set(state => ({allDocsItems: result.data.result}))
            
        }catch(err){
            console.log(err)
        }
    },
    updateDocsItem: async (id, item) => {
        try{
            let body = [id, item]
            await api.updateDocsItem(body)

            let result = await api.getDocsItems()
            set(state => ({allDocsItems: result.data.result}))
            
        }catch(err){
            console.log(err)
        }
    },
    sortDocsItems: async (type) => {
        try{
            let allItems = get().allDocsItems
            if(allItems?.length > 0 ){
                let filtered = await allItems.filter(i => i.type === type)
                let sorted = await filtered.sort((a,b) => a.order - b.order)
                set(state => ({sortedDocsItems: sorted}))
                }else{
                    set(state => ({sortedDocsItems: []})) 
                }
        }catch(err){
            console.log(err)
        }
    },
    setIndex: async (originIndex, newIndex) => {
        if(originIndex !== newIndex){
            originIndex++
            newIndex++
            let oldArr = await get().sortedDocsItems
            let newArr = await oldArr.map(i => {
                if((i.order < newIndex && i.order < originIndex) || (i.order > newIndex && i.order > originIndex)){
                    return i
                }else if(i.order === originIndex) {
                    return ({...i, order: newIndex})
                }else{
                    if(i.order >= newIndex && i.order < originIndex){
                        return ({...i, order: i.order + 1})
                    }else if(i.order <= newIndex && i.order > originIndex){
                        return ({...i, order: i.order - 1})
                    }
                }
                
            })
             console.log(newArr.sort((a,b) => a.order - b.order))
            
            set(state => ({sortedDocsItems: newArr.sort((a,b) => a.order - b.order)}))
        }

    }, 
    saveIndexes: async () => {
        try{
            let body = []
            await get().sortedDocsItems?.forEach(i => {
                body.push({id: i._id, order: i.order})
            });
            
            await api.updateDocsIndexes(body)

            let result = await api.getDocsItems()
            set(state => ({allDocsItems: result.data.result}))
        }catch(err){
            console.log(err)
        }
    },
})


const documentsStore = create(devtools(store))

export default documentsStore