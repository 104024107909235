import React, {useState, useEffect} from 'react'
import CountUp from 'react-countup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlusCircle, faRefresh } from '@fortawesome/free-solid-svg-icons'
import incomeStore from '../../../../zustand/income';
import {motion} from 'framer-motion'
import Select from 'react-select';

const MoneyChart = () => {
  let [chartBg, setChartBg] = useState(``)
  let [chartSum, setChartSum] = useState(0)
  let [chartHover, setChartCover] = useState('')
  const [isBackpanelOpen, setIsBackpanelOpen] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")

  const [typeOption, setTypeOption] = useState({value: 'zrzutka', label: 'Typ wpływu: Zrzutka'})
  const [typeOptions, setTypeOptions] = useState([{value: 'zrzutka', label: 'Typ wpływu: Zrzutka'}, {value: 'inne', label: 'Typ wpływu: inne'}])

  const moneyChart = incomeStore(state => state.moneyChart)
  const getMoneyChartData = incomeStore(state => state.getMoneyChartData)
  const addIncomeItem = incomeStore(state => state.addIncomeItem)
  const deleteIncomeItem = incomeStore(state => state.deleteIncomeItem)

  const [newItem, setNewItem] = useState({
    type: "",
    name: "",
    link: "",
    value: 1,
  })

  useEffect(() => {
    getMoneyChartData()
  }, [])
  
  useEffect(() => {
    setErrorMsg("")
  }, [newItem])

  useEffect(() => {
    let sum = 0
    moneyChart?.forEach(i => {
      sum = sum + i.amount
    })
    setChartSum(sum)
  }, [moneyChart])


  useEffect(() => {
    let sum = 0
    moneyChart?.forEach(i => {
      sum = sum + i.amount
    })

    let result = `conic-gradient(`
    
    moneyChart?.forEach((item, i) => {
      let percentage = 0
      moneyChart?.forEach((x, y) => {
        if(i >= y){
          percentage = percentage + Math.round(((x.amount  / sum) * 100))

        }
      })
      if(i+1 < moneyChart?.length){
        if(chartHover === item.link){
          result = result.concat(`${item.activeColor} 0, ${item.activeColor} ${percentage}%,`)
        }else{
          result = result.concat(`${item.color} 0, ${item.color} ${percentage}%,`)
        }
      }else{
        if(chartHover === item.link){
          result = result.concat(`${item.activeColor} 0, ${item.activeColor} ${percentage}%`)
        }else{
          result = result.concat(`${item.color} 0, ${item.color} ${percentage}%`)
        }
      }

    })
    result = result.concat(")")
    setChartBg(result)
  }, [chartHover, moneyChart])

  const handleSubmit = (e) => {
    e.preventDefault()
    addIncomeItem(newItem, setIsBackpanelOpen, setErrorMsg)
}

useEffect(() => {
    setNewItem({...newItem, type: typeOption.value})
}, [typeOption])

  return (<>
    <div id="Home_Money" class="w-full  bg-black rounded text-white p-4">
            <div class="w-full text-center text-xl">🌊 Wpływy fundacji 
              <span onClick={() => {setIsBackpanelOpen(true)}} class="text-gray-300 cursor-pointer ml-2">
                <FontAwesomeIcon icon={faPlusCircle} />
              </span>
              <span onClick={() => {getMoneyChartData()}} class="text-gray-300 cursor-pointer ml-2">
                <FontAwesomeIcon icon={faRefresh} />
              </span>
            </div>
            <div class="w-full flex flex-col sm:flex-row lg:flex-col justify-around lg:justify-start items-center">
              <div id="Home_Chart" class="my-12">
                <div class="relative w-48 transition h-48 rounded-full" style={{background: chartBg}}>
                  <div class="bg-black absolute text-center w-36 h-36 m-auto top-0 left-0 right-0 bottom-0 
                  flex items-center justify-center text-bold text-xl" style={{borderRadius: '50%'}}>
                      <CountUp end={chartHover === "" ? chartSum : moneyChart?.filter(i => i.source === chartHover)[0].amount} /> zł
                  </div>
                </div>
              </div>
              <div id="Home_MoneyWariants" class="lg:w-full lg:mt-2">
                {moneyChart?.map((item, i) => (
                  <div class="w-full transition mt-4 flex justify-start items-center text-gray-400 hover:text-gray-200" key={`MoneyWariant${i}`}
                  onMouseEnter={() => setChartCover(item.source)} onMouseLeave={() => setChartCover('')}>
                    <div class="h-4 aspect-square rounded mr-2 transition" style={{backgroundColor: chartHover === item.source ? item.activeColor : item.color}} ></div>
                    <p>{item.source} 
                      <span class="text-red-600 cursor-pointer ml-2 opacity-40 hover:opacity-100 transition" 
                      onClick={() => deleteIncomeItem(item)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </span>
                    </p>
                  </div>
                ))}
              </div>
            </div>
    </div>
    {isBackpanelOpen && <div  class="fixed w-screen h-screen left-0 top-0 flex justify-center items-center" style={{zIndex: 4}}>
    <motion.div onClick={() => setIsBackpanelOpen(false)} initial={{opacity: 0}} animate={{opacity: 1}} class="fixed left-0 top-0 w-full h-full cursor-pointer" 
    style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}></motion.div>
        <motion.form initial={{scale: 0.8}} animate={{scale: 1}} style={{zIndex: 5}} onSubmit={handleSubmit}
        class="cursor-auto w-[90%] sm:w-2/3 md:w-1/2 lg:w-1/3 bg-gray-100 rounded p-6 flex flex-col items-center" >
            <h2 class="w-full text-center font-bold text-xl">Dodaj nowy wpływ</h2>
            <Select options={typeOptions} value={typeOption}  
                onChange={setTypeOption} styles={{
                  container: (baseStyles, state) => ({
                    ...baseStyles,
                    width: '75%',
                    margin: '1rem 0',
                    cursor: 'pointer',
                  }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    padding: '0 0.5rem',
                    height: '3.5rem',
                    background: 'rgb(229 231 235)',
                    border: 'none',
                    outline: 'none',
                    boxShadow: 'none',
                    cursor: 'pointer',
                  }),
            }}/>     
            <input required class="w-3/4 my-4 cursor-pointer bg-gray-200 outline-none h-14 rounded px-2 placeholder-black"  
            placeholder="Wpisz nazwe wypływu" type="text" maxLength={100} 
            value={newItem.name} onChange={(e) => setNewItem({...newItem, name: e.target.value})} />
            {typeOption.value === "zrzutka" ? 
            <input required class="w-3/4 my-4 cursor-pointer bg-gray-200 outline-none h-14 rounded px-2 placeholder-black"  
            placeholder="Wpisz id zrzutki" type="text" maxLength={6} minLength={6}
            value={newItem.link} onChange={(e) => setNewItem({...newItem, link: e.target.value})} /> :
            <input required class="w-3/4 my-4 cursor-pointer bg-gray-200 outline-none h-14 rounded px-2 placeholder-black"  
            placeholder="Wpisz wysokość wpływu" type="number" min={1}
            value={newItem.value} onChange={(e) => setNewItem({...newItem, value: e.target.value})} />}
            <button type='submit' class="w-3/4 mt-6 text-center bg-black text-white cursor-pointer p-2 rounded hover:bg-gray-800 transition">Dodaj wpływ</button>
            <span class="h-4 text-sm text-red-400 mt-2">{errorMsg}</span>
        </motion.form>
    </div>}
</>)
}

export default MoneyChart