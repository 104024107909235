import React, {useState, useEffect} from 'react'
import login1 from '../../assets/login1.jpg'
import {motion} from 'framer-motion'
import { useGoogleLogin, GoogleLogin } from '@react-oauth/google';
import userStore from '../../zustand/user';
import {useCookies} from 'react-cookie'

const Login = () => {
  const logInUser = userStore(state => state.logInUser)
  const checkUser = userStore(state => state.checkUser)
  const [cookies, setCookie, removeCookie] = useCookies(["userToken"]);
  const [isLoaded, setIsLoaded] = useState(false)
  const login = useGoogleLogin({
    onSuccess: tokenResponse => logInUser(tokenResponse.access_token, setCookie), 
    onError: error => console.log(error)
    
  });

  useEffect(() => {
    let checkIsCookiesOk = async () => {
      await checkUser()
      setIsLoaded(true)
    }
    checkIsCookiesOk()
  }, [])
  return (
    <div class="w-screen h-screen flex-row justify-center items-center flex bg-inherit">
      {isLoaded && <>
      <motion.div class="w-full md:w-1/2 md:bg-gray-200 lg:bg-inherit md:drop-shadow-lg lg:drop-shadow-none h-4/5 rounded flex-col justify-center items-center flex text-center	" 
      initial={{scale: .6}} animate={{scale: 1}} transition={{duration: .34}}>
        <p style={{fontFamily: 'Sedgwick Ave Display', color: '#E07566'}} class="text-7xl">WAP</p>
        <h2 class="text-2xl font-bold	mt-20">Zaloguj do panelu administracyjnego</h2>
        <a href="https://corignis.org/"><p class="cursor-pointer text-base text-gray-400 mt-6">Trafiles tu przypadkowo? <span class="underline">Wróć na strone główną</span></p></a>
        <div  onClick={() => login()} class="p-5 rounded bg-gray-900 mt-20 text-white cursor-pointer hover:bg-gray-800 duration-300	">Zaloguj przy pomocy google</div>
      </motion.div>
      <div class="hidden w-1/2 h-full lg:block">
        <img src={login1} class="w-full h-full " alt="Obraz krzepiacy na duchu"/>
      </div></>}
    </div>
  )
}

export default Login