import React, {useState, useEffect} from 'react'
import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cmsStore from '../../../../zustand/cms'
import translatingStore from '../../../../zustand/translating'
import Select from 'react-select';

const CmsForm = ({type, setIsBackpanelOpen, item}) => {

    const addCmsItem = cmsStore(state => state.addCmsItem)
    const updateCmsItem = cmsStore(state => state.updateCmsItem)
    const languages = translatingStore(state => state.languages)

    const [newItem, setNewItem] = useState({
        type: type,
        name: item ? item.name : {},
        desc: item ? item.desc : {},
        link: item ? item.link :  "",
        mail: item?.mail ? item.mail : "",
        country: item ? item.country :  {value: "pl", label: "Kraj: Polska"},
        img: null,
    })
    
    const [isOnlyPhoto, setIsOnlyPhoto] = useState(false)
    const [isLinkAdded, setIsLinkAdded] = useState(true)
    const [isAdmin, setIsAdmin] = useState(false)
    const [langOption, setLangOption] = useState({value: languages[0]?._id, label: languages[0]?.name})
    const [langOptions, setLangOptions] = useState([])

    useEffect(() => {
        if(type === "Zespół" || type === "Współprace" || type === "Prasa"){
            setIsOnlyPhoto(false)
        }else{
            setIsOnlyPhoto(true)
        }

        if(item && item.link === "Brak linku"){
            setIsLinkAdded(false)
        }
        if(item && item.mail !== ""){
            setIsAdmin(true)
        }

        let arr = []
        languages?.forEach(i => {
            arr.push({value: i?._id, label: i?.name})
        })
        setLangOptions(arr)
    }, [])

    useEffect(() => {
        if(isLinkAdded){
            if(item){
                setNewItem({...newItem, link: item.link})
            }else{
                setNewItem({...newItem, link: ""})
            }
        }else{
            setNewItem({...newItem, link: "Brak linku"})
        }
    }, [isLinkAdded])

    useEffect(() => {
        if(isAdmin){
            if(item?.mail){
                setNewItem({...newItem, mail: item.mail})
            }else{
                setNewItem({...newItem, mail: ""})
            }
        }else{
            setNewItem({...newItem, mail: ""})
        }
    }, [isAdmin])

    const handleSubmit = (e) => {
        e.preventDefault()
        if(item){
            updateCmsItem(item._id, newItem)
        }else{
            addCmsItem(newItem)
        }
        
        setIsBackpanelOpen(false)
    }

    const addPhoto = (e) => {
        setNewItem({...newItem, img: e.target.files[0]})
        console.log(e.target.files[0])
    }

    // useEffect(() => {
    //     setNewItem({...newItem, country: countryOption})
    // }, [])
 
    return (
        <form onSubmit={handleSubmit} class="w-full lg:w-3/4 flex flex-col items-center">
            <h2 class="w-full text-center font-bold text-xl">{item ? 'Edytuj element' : 'Dodaj nowy element' }</h2>
            <Select options={langOptions} value={langOption}  
                onChange={setLangOption} styles={{
                  container: (baseStyles, state) => ({
                    ...baseStyles,
                    width: '100%',
                    margin: '1rem 0',
                    cursor: 'pointer',
                  }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    padding: '0 0.5rem',
                    height: '3.5rem',
                    background: 'rgb(229 231 235)',
                    border: 'none',
                    outline: 'none',
                    boxShadow: 'none',
                    cursor: 'pointer',
                  }),
            }}/>
            {type === "Galeria" && <Select options={[
                {value: "pl", label: "Kraj: Polska"},
                {value: "ua", label: "Kraj: Ukraina"},
                {value: "ru", label: "Kraj: Rosja"},
                {value: "sk", label: "Kraj: Słowacja"},
                {value: "lv", label: "Kraj: Łotwa"},
                {value: "lt", label: "Kraj: Litwa"},
                {value: "ee", label: "Kraj: Estonia"},
                {value: "cz", label: "Kraj: Czechy"},
                {value: "by", label: "Kraj: Białoruś"},
            ]} value={newItem.country}  
                onChange={(e) => setNewItem({...newItem, country: e})} styles={{
                  container: (baseStyles, state) => ({
                    ...baseStyles,
                    width: '100%',
                    margin: '1rem 0',
                    cursor: 'pointer',
                  }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    padding: '0 0.5rem',
                    height: '3.5rem',
                    background: 'rgb(229 231 235)',
                    border: 'none',
                    outline: 'none',
                    boxShadow: 'none',
                    cursor: 'pointer',
                  }),
            }}/>}
            {!isOnlyPhoto && <>
                {type === "Zespół" && <input required class="w-full my-4 cursor-pointer bg-gray-200 outline-none h-14 rounded px-2 placeholder-black"  
                placeholder={"Wpisz imie i nazwisko"} type="text" maxLength={100} 
                value={newItem.name[langOption.value] ? newItem.name[langOption.value] : ""} 
                onChange={(e) => setNewItem({...newItem, name: {...newItem.name, [langOption.value]: e.target.value}})} />}
                {type === "Współprace" && <input required class="w-full my-4 cursor-pointer bg-gray-200 outline-none h-14 rounded px-2 placeholder-black"  
                placeholder={"Wpisz nazwe firmy"} type="text" maxLength={100} 
                value={newItem.name[langOption.value] ? newItem.name[langOption.value] : ""} 
                onChange={(e) => setNewItem({...newItem, name: {...newItem.name, [langOption.value]: e.target.value}})} />}
                {type === "Prasa" && <input required class="w-full my-4 cursor-pointer bg-gray-200 outline-none h-14 rounded px-2 placeholder-black"  
                placeholder={"Wpisz autora"} type="text" maxLength={100} 
                value={newItem.name[langOption.value] ? newItem.name[langOption.value] : ""} 
                onChange={(e) => setNewItem({...newItem, name: {...newItem.name, [langOption.value]: e.target.value}})} />}
            </>}
            {type !== "Współprace" && <>
                {type === "Zespół" && <input required class="w-full my-4 cursor-pointer bg-gray-200 outline-none h-14 rounded px-2 placeholder-black"  
                placeholder={"Wpisz stanowisko"}  
                value={newItem.desc[langOption.value] ? newItem.desc[langOption.value] : ""} 
                onChange={(e) => setNewItem({...newItem, desc: {...newItem.desc, [langOption.value]: e.target.value}})} type="text" maxLength={150} /> }
                {type === "Galeria" && <input required class="w-full my-4 cursor-pointer bg-gray-200 outline-none h-14 rounded px-2 placeholder-black"  
                placeholder={"Wpisz opis zdjęcia"}  
                value={newItem.desc[langOption.value] ? newItem.desc[langOption.value] : ""} 
                onChange={(e) => setNewItem({...newItem, desc: {...newItem.desc, [langOption.value]: e.target.value}})} type="text" maxLength={150} /> }
                {type === "Inne zdjęcia" && <input required class="w-full my-4 cursor-pointer bg-gray-200 outline-none h-14 rounded px-2 placeholder-black"  
                placeholder={"Wpisz opis zdjęcia"}  
                value={newItem.desc[langOption.value] ? newItem.desc[langOption.value] : ""} 
                onChange={(e) => setNewItem({...newItem, desc: {...newItem.desc, [langOption.value]: e.target.value}})} type="text" maxLength={150} /> }
                {type === "Prasa" && <input required class="w-full my-4 cursor-pointer bg-gray-200 outline-none h-14 rounded px-2 placeholder-black"  
                placeholder={"Cytat"}  
                value={newItem.desc[langOption.value] ? newItem.desc[langOption.value] : ""} 
                onChange={(e) => setNewItem({...newItem, desc: {...newItem.desc, [langOption.value]: e.target.value}})} type="text" maxLength={400} /> }
            </>}
            {type === "Zespół" &&  <div class="w-full flex justify-between items-center">
                <input required={isAdmin} class="flex-grow h-14 my-4 cursor-pointer bg-gray-200 outline-none rounded transition px-2 placeholder-black" 
                placeholder={isAdmin ? 'Wpisz mail logowania do WAP' : 'Czy ma mieć dostęp do WAP'}
                value={newItem.mail} style={{filter: !isAdmin && 'contrast(0.7)', pointerEvents: isAdmin ? 'auto' : 'none'}}  
                onChange={(e) => {isAdmin && setNewItem({...newItem, mail: e.target.value})}} type={isAdmin ? "email" : "text"} maxLength={250} />
                <div onClick={() => {setIsAdmin(!isAdmin)}} class="h-14 text-xl ml-4 aspect-square rounded cursor-pointer bg-gray-200 flex justify-center items-center">
                    {isAdmin && <FontAwesomeIcon icon={faCheck}/>}
                </div>
            </div>}
            {!isOnlyPhoto &&  <div class="w-full flex justify-between items-center">
                <div onClick={() => {setIsLinkAdded(!isLinkAdded)}} class="h-14 text-xl rounded mr-4 aspect-square cursor-pointer bg-gray-200 flex justify-center items-center">
                    {isLinkAdded && <FontAwesomeIcon icon={faCheck}/>}
                </div>
                <input required class="flex-grow h-14 my-4 cursor-pointer bg-gray-200 outline-none rounded transition px-2 placeholder-black" placeholder="Wpisz link" 
                value={newItem.link} style={{filter: !isLinkAdded && 'contrast(0.7)', pointerEvents: isLinkAdded ? 'auto' : 'none'}}  
                onChange={(e) => {isLinkAdded && setNewItem({...newItem, link: e.target.value})}} type={isLinkAdded ? "url" : "text"} maxLength={250} />
            </div>}
            {!item &&<div class="w-full flex justify-between items-center">
                <input required class="flex-grow h-14 my-4 cursor-pointer pointer-events-none bg-gray-200 outline-none rounded px-2 placeholder-black" placeholder="Dodaj zdjęcie" 
                value={newItem?.img?.name}  style={{filter: 'contrast(0.7)'}}  type="text" maxLength={200} />
                <div class="h-14 text-xl ml-4 aspect-square cursor-pointer  bg-gray-200 rounded flex justify-center items-center relative ">
                    <FontAwesomeIcon icon={faPlus}/>
                    <input class="w-full h-full opacity-0 absolute left-0 top-0 cursor-pointer"  accept="image/*" type="file" onChange={addPhoto} />
                </div>
            </div>}
            <button type='submit' class="w-full mt-6 text-center bg-black text-white cursor-pointer p-2 rounded hover:bg-gray-800 transition">Zapisz element</button>
        </form>
    )
}

export default CmsForm