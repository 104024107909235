import React from 'react'
import {motion} from 'framer-motion'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCookieBite } from '@fortawesome/free-solid-svg-icons'
import { searchItem } from '../../../../animations/animations'


const SearchItem = ({item, close}) => {
const {title, breadcrumbs, link} = item
  return (
    <Link to={link} onClick={close}>
        <motion.div class="mt-2 md:mt-4 w-full h-16 flex flex-col justify-around items-start rounded p-2 cursor-pointer
             border-solid border-2 hover:border-gray-600 whitespace-nowrap" variants={searchItem} initial="start" animate="end">
            <p class="font-semibold text-l md:text-xl">{title}</p>
            <p class="text-sm md:text-md text-gray-600"><FontAwesomeIcon icon={faCookieBite}/> {breadcrumbs}</p>
        </motion.div>
    </Link>
  )
}

export default SearchItem