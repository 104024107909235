import React, {useState, useEffect} from 'react'
import { faPlus, faQuestionCircle, faPen, faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {motion} from 'framer-motion'
import { deleteForm } from '../../../../animations/animations'
import FileBase64 from 'react-file-base64';
import translatingStore from '../../../../zustand/translating'

const LanguageItem = ({item}) => {
    const [isBackpanelOpen, setIsBackpanelOpen] = useState(false)
    const [newItem, setNewItem] = useState({
        img: null,
        name: 'Nowy język',
        isPublic: false,
    })

    useEffect(() => {
        if(item){
            setNewItem({
                img: item.img,
                name: item.name,
                isPublic: item.isPublic,
            })
        }
    }, [])

    const [nameEditing, setNameEditing] = useState(false)
    const [delLangForm, setDelLangForm] = useState(false)
    const [delLangFormInput, setDelLangFormInput] = useState("")
    const [delLangError, setDelLangError] = useState("Aby usunąć język wpisz jego nazwe")
    const [errorMsg, setErrorMsg] = useState(false)

    const saveLanguages = translatingStore(state => state.saveLanguages)
    const deleteLanguage = translatingStore(state => state.deleteLanguage)
    const addLanguage = translatingStore(state => state.addLanguage)

    const handleDeleteLang = () => {
        setNameEditing(false)
            if(delLangFormInput === newItem.name){
                deleteLanguage(item._id, setIsBackpanelOpen, setDelLangError)
            }else{
                setDelLangError('Nazwy się nie pokrywają')
            }
    }
    useEffect(() => {
        setDelLangError('Aby usunąć język wpisz jego nazwe')
    }, [delLangFormInput])

    const imgChange = (e) => {
        if(e.type.slice(0, 5) === "image"){
            setNewItem({...newItem, img: e.base64})
        }
    }
    const saveChanges = () => {
        if(item){
            saveLanguages(newItem, item._id, setIsBackpanelOpen, setErrorMsg)
        }else{
            if(newItem.img !== null){
                addLanguage(newItem, setIsBackpanelOpen, setErrorMsg)
                setNewItem({
                    img: null,
                    name: 'Nowy język',
                    isPublic: false,
                })
            }else{
                setErrorMsg('Dodaj zdjęcie')
            }
        }
    }

    useEffect(() => {
        setErrorMsg(null)
    }, [newItem])


    return (    
      <> {!item ? 
                <div onClick={() => setIsBackpanelOpen(true)} class="p-4 w-full sm:w-[26%] lg:w-[21%] mr-[7%] lg:mr-[4%] my-4 rounded bg-gray-200 hover:bg-gray-100 hover:shadow-lg
                transition duration-300 cursor-pointer flex items-center" >
                    <FontAwesomeIcon icon={faPlus} class="h-8 mr-4"/>
                    <span>Dodaj nowy</span>
                </div>
                :<>{item.name !== "Polski" ? 
                <div onClick={() => setIsBackpanelOpen(true)} class="p-4 w-full sm:w-[26%] lg:w-[21%] mr-[7%] lg:mr-[4%] my-4 rounded bg-gray-200 hover:bg-gray-100 hover:shadow-lg
                transition duration-300 cursor-pointer flex items-center" >
                    <img src={item.img} class="h-8 mr-4"/>
                    <span>{item.name}</span>
                </div> :
                <div class="p-4 w-full sm:w-[26%] lg:w-[21%] mr-[7%] lg:mr-[4%] my-4 rounded bg-gray-300 
                transition duration-300 flex items-center" >
                    <img src={item.img} class="h-8 mr-4"/>
                    <span>{item.name}</span>
                </div>
                }</>
            }
        {isBackpanelOpen && <div  class="fixed w-screen h-screen left-0 top-0 flex justify-center items-center" style={{zIndex: 4}}>
            <motion.div onClick={() => setIsBackpanelOpen(false)} initial={{opacity: 0}} animate={{opacity: 1}} class="fixed left-0 top-0 w-full h-full cursor-pointer" 
            style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}></motion.div>
            <motion.div initial={{scale: 0.8}} animate={{scale: 1}} class="cursor-auto w-[90%] sm:w-2/3 md:w-1/2 lg:w-1/3 bg-gray-100 rounded p-6 flex flex-col" style={{zIndex: 5}}>
              <div class="w-full flex  justify-center">
                {!newItem.img  ? <FontAwesomeIcon icon={faQuestionCircle} class="h-24"/> : <img src={newItem.img} class="h-24" />}
              </div>
              <div class="w-full mt-16 flex justify-between items-center">
                    {nameEditing ? 
                        
                        <input maxLength={30} class="text-2xl outline-none bg-transparent border-b-2 w-2/3 border-solid border-black" value={newItem.name} onChange={(e) => setNewItem({...newItem, name: e.target.value})} /> :
                        <h2 class="text-2xl">{newItem.name}</h2>
                        
                    }
                    {nameEditing ? 
                        <span onClick={() => setNameEditing(false)} class="h-4 ml-4 cursor-pointer text-green-400 font-bold" >Zapisz</span> :
                        <span onClick={() => setNameEditing(true)} class="h-4 ml-4 cursor-pointer text-yellow-400 font-bold" >Edytuj</span>
                    }
                </div>
                <div class="w-full mt-4 flex justify-between items-center">
                    <h2 class="text-2xl">Zdjęcie</h2>
                    <div id="ImgUpload" class="h-full cursor-pointer aspect-square relative flex justify-center items-center">
                    <span class="  text-yellow-400 font-bold" >Edytuj</span>
                    {/* <input type="file" inputProps={{accept: '.img, .png'}} class="absolute left-0 top-0 w-full h-full opacity-0 cursor-pointer" style={{zIndex: 5}} /> */}
                    <FileBase64
                    multiple={ false }
                    onDone={(e) => imgChange(e)} 
                    />
                    </div>
                </div>
                <div class="w-full mt-4 flex justify-between items-center">
                    <h2 class="text-2xl">Publiczny</h2>
                    <motion.div onClick={() => {setNewItem({...newItem, isPublic: !newItem.isPublic})}} class="w-12 h-6 rounded-2xl p-1 cursor-pointer overflow-hidden relative bg-gray-200">
                        <motion.div initial={{x: '100%'}} animate={{x: newItem.isPublic ? '100%' : 0}} transition={{type: 'linear', duration: 0.3, delay: 0}} 
                        class="absolute top-[2.5%] h-[95%] aspect-square rounded-full bg-white" style={{zIndex: 5}}></motion.div>
                        <motion.div initial={{x: '-10%'}} animate={{x: newItem.isPublic ? '-10%' : '-110%'}} transition={{type: 'linear', duration: 0.3, delay: 0}} 
                        class="absolute top-0 w-[120%] h-[120%] bg-black"></motion.div>
                    </motion.div>
                </div>
                {item && <div class="w-full mt-4 flex justify-between items-center">
                    <h2 class="text-2xl">Usunać język?</h2>
                    {!delLangForm ? 
                        <span onClick={() => setDelLangForm(true)} class="h-4 ml-4 cursor-pointer text-red-400 font-bold" >Usuń</span> :
                        <span onClick={() => setDelLangForm(false)} class="h-4 ml-4 cursor-pointer text-green-400 font-bold" >Anuluj</span>
                    }
                </div>}
                <div class="w-full h-56 flex flex-col justify-between">
                    <motion.div class="w-full flex flex-col " variants={deleteForm} initial="start" animate={!delLangForm ? 'start' : 'end'}>
                        <div class="w-full mt-4 flex justify-between items-center"> 
                            <input maxLength={30} class=" outline-none bg-transparent border-b-2 w-2/3 border-solid border-black"  placeholder="Nazwa języka" 
                            value={delLangFormInput} onChange={(e) => setDelLangFormInput(e.target.value)} />
                            <span onClick={handleDeleteLang} class="h-4 ml-4 cursor-pointer text-red-400 font-bold" >Usuń</span>
                        </div>
                        <p class="text-sm text-red-400 mt-2">{delLangError}</p>
                    </motion.div>
                    <div onClick={saveChanges} class="bg-black text-white cursor-pointer w-full  p-2 rounded text-center hover:bg-gray-800 transition">Zapisz zmiany</div> 
                    <div class="w-full h-4 flex justify-center items-center text-red-400">
                        {errorMsg && <motion.p variants={deleteForm} initial="start" animate="end">{errorMsg}</motion.p>}
                    </div>
                </div>

            </motion.div>
        </div>}
         </>  
    )
}

export default LanguageItem