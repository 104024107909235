import React, {useState, useEffect} from 'react'
import dashboardStore from '../../../zustand/dashboard'
import userStore from '../../../zustand/user'
import Chart from "react-apexcharts";
import { motion } from 'framer-motion';
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css'; 
import { DateRangePicker, DateRange  } from 'react-date-range';
import { subDays } from 'date-fns';
import optionsStore from '../../../zustand/options'
import QuickAction from './QuickAction/QuickAction';
import MoneyChart from './MoneyChart/MoneyChart';


const Home = () => {
  const getDashboardData = dashboardStore(state => state.getDashboardData)
  const runScrapeScripts = dashboardStore(state => state.runScrapeScripts)
  const daysChart = dashboardStore(state => state.daysChart)
  const countriesChart = dashboardStore(state => state.countriesChart)
  const isSiteBlocked = dashboardStore(state => state.isSiteBlocked)
  
  const getLogs = dashboardStore(state => state.getLogs)
  const restartApi = dashboardStore(state => state.restartApi)
  const openSite = dashboardStore(state => state.openSite)
  const name = userStore(state => state.name)
  const email = userStore(state => state.email)
  const windowSize = optionsStore(state => state.windowSize)
  const isDevMode = optionsStore(state => state.isDevMode)
  const toggleDevMode = optionsStore(state => state.toggleDevMode)

  let [isDatePanelOpen, setIsDatePanelOpen] = useState(false)
  let [datePanel, setDatePanel] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  let options = {
    chart: {
      id: "Statystki Cor Ignis",
      stacked: true,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      categories: daysChart?.map(i => `${i.dimensionValues[0].value.slice(0,4)}/${i.dimensionValues[0].value.slice(4,6)}/${i.dimensionValues[0].value.slice(6,8)}`)
    }
  }

  let series = [
    {
      name: "Nowi użytkownicy",
      data: daysChart?.map(i => i.metricValues[0].value)
    },
    {
      name: "Wszyscy użytkownicy",
      data: daysChart?.map(i => i.metricValues[1].value)
    },
    {
      name: "Średni czas wizyty (w minutach)",
      data: daysChart?.map(i => Math.round(i.metricValues[2].value) > 0 ? ((i.metricValues[2].value)/60).toFixed(2) : 0)
    },
  ]

  let options2 = {
    labels: countriesChart?.filter((item, i) => i <= 8 && item).map(i => i.dimensionValues[0].value),
    fill: {
      opacity: 0.5,
      colors: ['#616161']
    },
    stroke: {
      show: true,
      width: 2,
      opacity: 0.85,
      colors: ['#616161'],
      dashArray: 0
    },
    markers: {
      size: 5,
      opacity: 1,
      colors: ['#616161'],
      hover: {
        size: 10
      }
    },
    yaxis: {
      show: false,
    }

  }
  let series2 = [
    {
      name: "Miasta użytkowników strony",
      data: countriesChart?.filter((item, i) => i <= 8 && item )?.map(i => i.metricValues[0].value)
    },
  ]

  useEffect(() => {
    getDashboardData(datePanel)
  }, [datePanel])

  return (
    <div id="Home" class="w-full h-full sm:py-6 px-2 md:px-12 flex flex-col lg:flex-row">
        <div id="Home_ContentLeft" class="lg:h-full w-full lg:w-3/4 flex flex-col order-2 lg:order-1">
          <div id="Home_LeftNav" class="w-full">
            <h2 class="font-bold text-2xl">Szybkie akcje</h2>
            <div class="my-6 w-full flex flex-col sm:flex-row flex-wrap justify-start">
               <QuickAction isSiteBlocked={isSiteBlocked} text={isSiteBlocked ? 'Odblokuj strone' : 'Zablokuj strone'}
                desc={`Używając tej akcji ${isSiteBlocked ? 'odblokujesz' : 'zablokujesz'} dostęp do strony wszystkim użytkownikom poza członkami fundacji.`}
                authText={'Na twojego maila przyjdzie kod uwierzytelniający wpisz go poniżej'} />
                {isSiteBlocked && <QuickAction text="Przejdz do strony" endFunc={openSite}
                 desc="Używając tej akcji przejdziesz do strony omijając blokade" />}
               <QuickAction text="Zrestaruj API" endFunc={restartApi} 
               desc="Używając tej akcji zrestartujesz API - czyli moduł dostępu do informacji z bazy danych"/>
               <QuickAction text="Wykonaj skrypty ponownie" endFunc={runScrapeScripts} 
               desc="Używając tej akcji sprawisz, że wszystkie informacje z zewnętrznych źródeł zostaną ponownie pobrane do bazy danych"/>
               <QuickAction text="Pobierz logi" endFunc={() => getLogs({name, email})} 
               desc="Po użyciu tej akcji dostaniesz na swojego maila fundacyjnego wiadomość wraz z załącznikiem w postaci logów (dziennika zdarzeń) serwera"/>
               <QuickAction text={!isDevMode ? 'Włącz tryb developerski' : 'Wyłącz tryb developerski'} endFunc={toggleDevMode} 
               desc="Po użyciu tej akcji włączysz w panelu administracyjnym tryb developerski i zobaczysz funkcje w trakcie tworzenia"/>
            </div>
          </div>
          <div id="Home_LeftStats" class="w-full flex flex-col">
            <h2 class="font-bold text-2xl">Statystyki</h2>
            <div class="my-6 w-full flex flex-col md:flex-row ">
               <div id="Home_StatsMain" class="w-full md:w-4/6 h-full">
                  <Chart
                  options={options}
                  series={series}
                  type="area"
                  width="95%"
                  toolbar={{
                    show: false
                  }}
                  />
               </div>
               <div id="Home_StatsSecondary" class="w-full md:w-2/6 h-full flex md:flex-col px-4">
                  <div class="hidden md:block w-4/6 md:w-full h-full md:h-4/6">
                    <Chart
                    options={options2}
                    series={series2}
                    type="radar"
                    height="100%"
                    toolbar={{
                      show: false
                    }}
                    />
                  </div>
                  <div class="p-2 w-full mt-4 md:mt-0 md:w-full h-full md:h-2/6 rounded bg-gray-200 text-center flex flex-col justify-around">
                    <h2 class="font-bold">Zakres dat</h2>
                    <p>{`${datePanel[0].startDate.getDate()}/${datePanel[0].startDate.getMonth()+1}/${datePanel[0].startDate.getFullYear()} - ${datePanel[0].endDate.getDate()}/${datePanel[0].endDate.getMonth()+1}/${datePanel[0].endDate.getFullYear()}`}</p>
                    <div class="bg-black text-white cursor-pointer w-3/4 mx-auto p-2 rounded text-center
                     hover:bg-gray-800 transition" onClick={() => setIsDatePanelOpen(true)}>Zmień zakres dat</div>
                  </div>
               </div>
            </div>
          </div>
        </div>
        <div id="Home_ContentRight" class="mb-8 lg:mb-0 lg:h-full w-full lg:w-1/4 order-1 lg:order-2">
          <MoneyChart />
        </div>
        {isDatePanelOpen && <div id="Home_DatePanel" class="fixed w-screen h-screen left-0 top-0 flex justify-center items-center" style={{zIndex: 4}}>
          <motion.div onClick={() => setIsDatePanelOpen(false)} initial={{opacity: 0}} animate={{opacity: 1}} class="fixed left-0 top-0 w-full h-full cursor-pointer" 
          style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}></motion.div>
          <motion.div initial={{scale: 0.8}} animate={{scale: 1}} class="cursor-auto bg-gray-100 rounded p-6 " style={{zIndex: 5}}>
            {windowSize  > 640 ? <DateRangePicker 
              onChange={item => setDatePanel([item.selection])}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={windowSize  > 1024 ? 2 : 1}
              ranges={datePanel}
              direction="horizontal"/> : <DateRange 
              onChange={item => setDatePanel([item.selection])}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={1}
              ranges={datePanel}
              direction="vertical"/> }
          </motion.div>
        </div>}
    </div>
  )
}

export default Home