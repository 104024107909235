import React, {useState, useEffect} from 'react'
import { motion } from 'framer-motion'
import optionsStore from '../../../../zustand/options'
import userStore from '../../../../zustand/user'
import dashboardStore from '../../../../zustand/dashboard'

const QuickAction = ({authText, isSiteBlocked, endFunc, text, desc}) => {
    const [isBackpanelOpen, setIsBackpanelOpen] = useState(false)
    const [isAuthSend, setIsAuthSend] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const sendAuthRequest = optionsStore(state => state.sendAuthRequest)
    const blockSite = dashboardStore(state => state.blockSite)
    const name = userStore(state => state.name)
    const email = userStore(state => state.email)
    const [authCode, setAuthCode] = useState(['', '', '', '', '', ''])

    const handleAuthorizationStep1 = () => {
        setErrorMsg("")
        sendAuthRequest(setErrorMsg, setIsAuthSend, name, email)
    }
    const handleAuthorizationStep2 = () => {
        setErrorMsg("")
        blockSite(authCode, setErrorMsg, setIsBackpanelOpen)
    }

    const handleInputChange = async (e, index) => {
        let newArr = [...authCode]
        newArr[index] = e.target.value
        setAuthCode(newArr)
        e.target.nextSibling.focus()
    }

    useEffect(() => {
        setAuthCode(['', '', '', '', '', ''])
        setIsAuthSend(false)
    }, [isBackpanelOpen])

    useEffect(() => {
        setErrorMsg('')
    }, [authCode])

    return (
        <>{!authText ? 
        <div onClick={() => setIsBackpanelOpen(true)} class="p-4 w-full sm:w-[26%] my-4 rounded bg-gray-200 hover:bg-gray-100 hover:shadow-lg
        transition duration-300 cursor-pointer" style={{ marginRight: '7%'}} >
            {text}
        </div> :               
        <div onClick={() => setIsBackpanelOpen(true)} class="p-4 w-full sm:w-[26%] my-2 sm:my-4 rounded grayscale-[25%] hover:grayscale-0 hover:shadow-lg transition duration-300 cursor-pointer"
        style={{ marginRight: '7%', backgroundColor: !isSiteBlocked ? '#de2e21' : '#66de21'}}>{text}</div>
        }
        {isBackpanelOpen && <div id="Home_DatePanel" class="fixed w-screen h-screen left-0 top-0 flex justify-center items-center" style={{zIndex: 4}}>
          <motion.div onClick={() => setIsBackpanelOpen(false)} initial={{opacity: 0}} animate={{opacity: 1}} class="fixed left-0 top-0 w-full h-full cursor-pointer" 
          style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}></motion.div>
          <motion.div initial={{scale: 0.8}} animate={{scale: 1}} class="cursor-auto w-[90%] sm:w-2/3 md:w-1/2 lg:w-1/3 bg-gray-100 rounded p-6 " style={{zIndex: 5}}>
            {!authText ? <>
            <h2 class="w-full text-center font-bold text-2xl">Jesteś pewny?</h2>
            <p class="w-full text-center my-4">{desc}</p>
            <div class="w-full flex flex-col sm:flex-row justify-around">
                <div onClick={() => {endFunc(); setIsBackpanelOpen(false)}} class="bg-black text-white cursor-pointer w-full sm:w-3/5 mx-auto mt-2 p-2 rounded text-center
                hover:bg-gray-800 transition">Jestem pewny</div>
                <div onClick={() => setIsBackpanelOpen(false)} class="bg-gray-200 hover:bg-gray-300 text-black cursor-pointer w-full sm:w-1/5 mx-auto mt-2 p-2 rounded text-center
                 transition">Anuluj</div>
            </div></> :<>
            <h2 class="w-full text-center font-bold text-2xl">{isAuthSend ? 'Autoryzacja' : 'Jesteś pewny?'}</h2>
            <p class="w-full text-center my-4">
                {isAuthSend ? authText : desc}
            </p>
            {isAuthSend && 
                <div class="w-full h-12 flex justify-around">
                    {authCode.map((item,i) => <input key={`Input${i}`} autoFocus={i === 0} value={authCode[i]} onChange={(e) => handleInputChange(e, i)} type='text' maxLength={1} 
                    class="w-[10%] h-full bg-transparent border-black rounded border-2 flex justify-center items-center text-2xl text-center uppercase"></input>)}
                </div>
            }
            <p class="w-full text-center font-bold text-sm text-red-400 my-4">{errorMsg}</p>
            <div class="w-full flex flex-col sm:flex-row justify-around">
                <div onClick={isAuthSend ? handleAuthorizationStep2 : handleAuthorizationStep1} class="bg-black text-white cursor-pointer w-full sm:w-3/5 mx-auto mt-2 p-2 rounded text-center
                hover:bg-gray-800 transition">{isAuthSend ? 'Autoryzacja' : 'Jesteś pewny?'}</div>
                <div onClick={() => setIsBackpanelOpen(false)} class="bg-gray-200 hover:bg-gray-300 text-black cursor-pointer w-full sm:w-1/5 mx-auto mt-2 p-2 rounded text-center
                 transition">{isAuthSend ? 'Anuluj akcje' : 'Anuluj'}</div>
            </div></>}
          </motion.div>
        </div>}
        </>
    )
}

export default QuickAction