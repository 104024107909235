import create from 'zustand'
import { devtools } from 'zustand/middleware'
import data from './data/notifiData'

const store = (set, get) => ({
    notifications: [],
    newNotifications: [],
    getNotifications: async () => {
        set(state => ({notifications: data}))
        set(state => ({newNotifications: data.filter(i => i.new === true ? i : null)}))
    },
})


const notificationsStore = create(devtools(store))

export default notificationsStore