import React, {useState, useEffect} from 'react'
import { faGear } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {motion} from 'framer-motion'
import Select from 'react-select';
import translatingStore from '../../../../zustand/translating'


const SettingsItem = () => {
    const [isBackpanelOpen, setIsBackpanelOpen] = useState(false)
    const [options, setOptions] = useState({})
    const languages = translatingStore(state => state.languages)
    const languagesSettings = translatingStore(state => state.languagesSettings)
    const getLanguagesSettings = translatingStore(state => state.getLanguagesSettings)
    const changeLanguagesSettings = translatingStore(state => state.changeLanguagesSettings)

    useEffect(() => {
        let arr = []
        languages?.filter(i => i.isPublic === true).forEach(i => {
            arr.push({value: i.name, label: i.name})
        })
        setOptions(arr)
    }, [languages])

    useEffect(() => {
        getLanguagesSettings()
    }, [])

    return (
        <>
            <div onClick={() => setIsBackpanelOpen(true)} class="p-4 w-full sm:w-[26%] lg:w-[21%] mr-[7%] lg:mr-[4%] my-4 rounded bg-gray-200 hover:bg-gray-100 hover:shadow-lg
            transition duration-300 cursor-pointer flex items-center" >
                <FontAwesomeIcon icon={faGear} class="h-8 mr-4"/>
                <span>Ustawienia</span>
            </div>
            {isBackpanelOpen && <div  class="fixed w-screen h-screen left-0 top-0 flex justify-center items-center" style={{zIndex: 4}}>
            <motion.div onClick={() => setIsBackpanelOpen(false)} initial={{opacity: 0}} animate={{opacity: 1}} class="fixed left-0 top-0 w-full h-full cursor-pointer" 
            style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}></motion.div>
            <motion.div initial={{scale: 0.8}} animate={{scale: 1}} class="cursor-auto w-[90%] sm:w-2/3 md:w-1/2 lg:w-1/3 bg-gray-100 rounded p-6 flex flex-col" style={{zIndex: 5}}>
                <h2 class="w-full text-center font-bold text-xl">Domyślny język dla użytkowników z:</h2>
                {languagesSettings?.map(item => {
                    let val = languages?.filter(i => i._id === item.language && i)[0].name
                    return(
                    <div class="w-full flex items-center justify-between my-4 ">
                        <p class="text-xl">{item.name}</p>
                        <Select options={options} value={options.filter(option => option.value === val && option)}
                         onChange={e => changeLanguagesSettings(e.value, item, setIsBackpanelOpen)} />
                    </div>
                )})}
            </motion.div>
            </div>}
        </>
    )
}

export default SettingsItem