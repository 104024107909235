import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const NavBtn = ({icon, text, onClick, active}) => {
  return (
    <div onClick={onClick} class="w-full p-4 cursor-pointer hover:bg-black hover:text-white
     transition mt-2" style={{backgroundColor: active && 'black', color: active && 'white'}}><FontAwesomeIcon icon={icon}/> <span class="ml-2">{text}</span></div>
  )
}

export default NavBtn