import React, {useState, useEffect} from 'react'
import {motion} from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot,  faExclamation, faTrash, faCheck } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { notifItem } from '../../../../animations/animations'
import optionsStore from '../../../../zustand/options'

const NotifItem = ({item, notifType, setIsNotifOpen}) => {
    const small = setIsNotifOpen ? true : false
    const {link, title, date, moreInfo, mail} = item
    const [icon, setIcon] = useState(faExclamation)
    const navigate = useNavigate()
    const windowSize = optionsStore(state => state.windowSize)
    const setIsBackpanelOpen = optionsStore(state => state.setIsBackpanelOpen)
    useEffect(() => {
        switch (link) {
            case '/Ignitorium':
                setIcon(faLocationDot)
                break;
            default: 
                setIcon(faExclamation)
                break;
        }
    }, [])

    const handleClick = () => {
        ///usun new
        navigate(link)
        if(small) {
            setIsBackpanelOpen()
            setIsNotifOpen()
        }
    }

    return (<>
        {!small ? <motion.wrapper variants={notifItem} initial="start" animate="end"  class="mt-6 w-full h-16 flex justify-between">
            <div onClick={handleClick} class="h-full flex-grow flex  rounded p-2 cursor-pointer
             border-solid border-2 hover:border-gray-600" >
                {windowSize > 450 && <div class="mr-4 h-full aspect-square border-2 border-solid border-gray-900 rounded-full flex justify-center items-center text-xl">
                    <FontAwesomeIcon icon={icon} /> 
                </div>}
                <div class="h-full flex-grow overflow-hidden pr-2 flex flex-col items-around justify-start ">
                    <div class="w-full whitespace-nowrap"><span class="font-bold">{title}</span><span>{windowSize > 600 && `, ${date.getHours() +1}:${date.getMinutes() +1} ${date.toLocaleString('en-GB', { month: 'short' })} ${date.getDate()}, ${date.getFullYear()}`}</span></div>
                    <div class="text-gray-500 w-full whitespace-nowrap">{`<${mail}>`} {windowSize > 600 && moreInfo}</div>
                </div>
            </div>
            <div class="ml-2 md:ml-8 h-full aspect-square rounded cursor-pointer border-solid border-2 hover:border-gray-600 flex justify-center items-center text-xl">
                <FontAwesomeIcon icon={notifType === 'Archive' ? faTrash : faCheck} /> 
            </div> 
        </motion.wrapper>: 
        <wrapper  class="mt-4 w-full h-10 flex  cursor-pointer" onClick={handleClick}>
            <div class="mr-4 h-full aspect-square border-2 border-solid border-gray-900 rounded-full flex justify-center items-center text-l">
                <FontAwesomeIcon icon={icon} /> 
            </div>
            <div class="h-full flex-grow overflow-hidden pr-2 flex flex-col  justify-around ">
                <p class="w-full text-gray-500  whitespace-nowrap text-xs">{`<${mail}>`}</p>
                <p class="w-full whitespace-nowrap text-sm font-bold">{title}</p>
            </div>
        </wrapper>}
    </>)
}

export default NotifItem