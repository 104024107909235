import create from 'zustand'
import { devtools } from 'zustand/middleware'
import * as api from '../api/index'

const store = (set, get) => ({
    allNews: [],
    sortedNews: [],
    getNews: async () => {
        try{
            let data = await api.getNewsItem()
            set(state => ({allNews: data.data.result}))
        }catch (err){
            console.log(err)
        }
    },
    sortNews: async (language) => {
        try{
            // let result = await api.getNewsItem()
            // let allNews = result.data.result
            let allNews = get().allNews
            if(allNews?.length > 0 ){
            let filtered = await allNews.filter(i => i.language === language.value)
            let sorted = await filtered.sort((a,b) => a.order - b.order)
            set(state => ({sortedNews: sorted}))
            }else{
                set(state => ({sortedNews: []})) 
            }
        }catch (err){
            console.log(err)
        }
    },
    setIndex: async (originIndex, newIndex) => {
        if(originIndex !== newIndex){
            let oldArr = await get().sortedNews

            let newArr = await oldArr.map(i => {
                if((i.order < newIndex && i.order < originIndex) || (i.order > newIndex && i.order > originIndex)){
                    return i
                }else if(i.order === originIndex) {
                    return ({...i, order: newIndex})
                }else{
                    if(i.order >= newIndex && i.order < originIndex){
                        return ({...i, order: i.order + 1})
                    }else if(i.order <= newIndex && i.order > originIndex){
                        return ({...i, order: i.order - 1})
                    }
                }
                
            })
            
            set(state => ({sortedNews: newArr.sort((a,b) => a.order - b.order)}))
        }

    }, 
    saveIndexes: async () => {
        try{
            // await get().sortedNews
            let body = []
            await get().sortedNews?.forEach(i => {
                body.push({id: i._id, order: i.order})
            });
            await api.updateIndexes(body)

            let data = await api.getNewsItem()
            set(state => ({allNews: data.data.result}))
        }catch(err){
            console.log(err)
        }
    },
    setIsPublic: async (item) => {
        try{
            await api.updateIsPublic(item)
            let data = await api.getNewsItem()
            set(state => ({allNews: data.data.result}))
        }catch(err){
            console.log(err)
        }
    },
    addNews: async (item) => {
        try{
            await api.addNewsItem(item)
        }catch (err){
            console.log(err)
        }
    },
    uptadeNews: async (id, item, handleChangeEditing) => {
        try{
            let body = [id, item]
            await api.updateNewsItem(body)
            handleChangeEditing(false)
        }catch (err){
            console.log(err)
        }
    },
    deleteNews: async (item, handleChangeEditing) => {
        try{
            await api.deleteNewsItem(item)
            await handleChangeEditing(false)
            let data = await api.getNewsItem()
            set(state => ({allNews: data.data.result}))
        }catch (err){
            console.log(err)
        }
    },
})


const newsStore = create(devtools(store))

export default newsStore