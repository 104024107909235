import create from 'zustand'
import { devtools } from 'zustand/middleware'
import * as api from '../api/index'

const store = (set, get) => ({
    incomeItems: [],
    moneyChart: [],
    getMoneyChartData: async () => {
        try{
            const result = await api.getMoneyChartData()
            set(state => ({moneyChart: result.data.moneyChart}))

        }catch(err){
            console.log(err)
        }
    },
    addIncomeItem: async (newItem, setIsBackpanelOpen, setErrorMsg) => {
        try{
            
            await api.addIncomeItem(newItem)

            const result = await api.getMoneyChartData()
            set(state => ({moneyChart: result.data.moneyChart}))

            setIsBackpanelOpen(false)
        }catch(err){
            console.log(err)
            setErrorMsg('Coś poszło nie tak')
        }
    },
    deleteIncomeItem: async (item) => {
        try{
            
            await api.deleteIncomeItem(item)

            const result = await api.getMoneyChartData()
            set(state => ({moneyChart: result.data.moneyChart}))

        }catch(err){
            console.log(err)
        }
    },

})


const incomeStore = create(devtools(store))

export default incomeStore