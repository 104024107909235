import axios from 'axios'

let API
if(process.env.REACT_APP_PRODUCTION === "true"){
    API = axios.create({baseURL: process.env.REACT_APP_API_LINK})
}else{
    API = axios.create({baseURL: 'http://localhost:5010'})
}


export const getDashboardData = (startDate, endDate) => API.get(`/admin/getGAData?startDate=${startDate}&endDate=${endDate}`)
export const getMoneyChartData = () => API.get(`/admin/getMoneyChartData`)
export const runScrapeScripts = () => API.post(`/admin/runScrapeScripts`)
export const blockSite = (code) => API.post(`/admin/blockSite`, code)
export const getIsSiteBlocked = () => API.get(`/admin/getIsSiteBlocked`)
export const getLogs = (recipient) => API.post(`/admin/getLogs`, recipient)
export const sendAuth = (recipient) => API.post(`/admin/sendAuth`, recipient)
export const verifyAuth = (code) => API.post(`/admin/verifyAuth`, code)
export const restartApi = () => axios.post('https://serverapi.corignis.org/restart')
export const getSiteLoginLink = () => API.get('/admin/getSiteLoginLink')
export const addLanguage = (newItem) => API.post('/admin/addLanguage', newItem)
export const saveLanguage = (newItem) => API.post('/admin/saveLanguage', newItem)
export const deleteLanguage = (id) => API.post('/admin/deleteLanguage', id)
export const getLanguages = () => API.get('/admin/getLanguages')
export const getLanguagesSettings = () => API.get('/admin/getLanguagesSettings')
export const changeLanguagesSettings = (body) => API.post('/admin/changeLanguagesSettings', body)
export const getTxtItems = () => API.get('/admin/getTxtItems')
export const addTextItem = (body) => API.post('/admin/addTextItem', body)
export const deleteTextItem = (item) => API.post('/admin/deleteTextItem', item)
export const updateTextItem = (body) => API.post('/admin/updateTextItem', body)
export const addNewsItem = (item) => API.post('/admin/addNewsItem', item)
export const getNewsItem = () => API.get('/admin/getNewsItem')
export const updateNewsItem = (body) => API.post('/admin/updateNewsItem', body)
export const deleteNewsItem = (item) => API.post('/admin/deleteNewsItem', item)
export const updateIndexes = (body) => API.post('/admin/updateIndexes', body)
export const updateIsPublic = (item) => API.post('/admin/updateIsPublic', item)
export const addCmsItem = (formData) => API.post('/admin/addCmsItem', formData, {headers: {'Content-Type': 'multipart/form-data'}})
export const getCmsItems = () => API.get('/admin/getCmsItems')
export const deleteCmsItem = (item) => API.post('/admin/deleteCmsItem', item)
export const updateCmsItem = (body) => API.post('/admin/updateCmsItem', body)
export const updateCmsIndexes = (body) => API.post('/admin/updateCmsIndexes', body)
export const verifyIsAdmin = (body) => API.post('/admin/verifyIsAdmin', body)
export const addIncomeItem = (newItem) => API.post('/admin/addIncomeItem', newItem)
export const deleteIncomeItem = (item) => API.post('/admin/deleteIncomeItem', item)
export const addDocsItem = (item) => API.post('/admin/addDocsItem', item)
export const getDocsItems = () => API.get('/admin/getDocsItems')
export const deleteDocsItem = (item) => API.post('/admin/deleteDocsItem', item)
export const updateDocsIndexes = (body) => API.post('/admin/updateDocsIndexes', body)
export const updateDocsItem = (body) => API.post('/admin/updateDocsItem', body)