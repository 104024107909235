const data = [
    {
        title: 'Zarządzanie',
        breadcrumbs: "Zarządzanie",
        link: '/Zarzadzanie',
    }, 
    {
        title: 'Zablokuj strone',
        breadcrumbs: "Zarządzanie > Zablokuj strone",
        link: '/Zarzadzanie',
    }, 
    {
        title: 'Odblokuj strone',
        breadcrumbs: "Zarządzanie > Odblokuj strone",
        link: '/Zarzadzanie',
    }, 
    {
        title: 'Zrestartuj API',
        breadcrumbs: "Zarządzanie > Zrestartuj API",
        link: '/Zarzadzanie',
    }, 
    {
        title: 'Wykonaj skrypty ponownie',
        breadcrumbs: "Zarządzanie > Wykonaj skrypty ponownie",
        link: '/Zarzadzanie',
    }, 
    {
        title: 'Pobierz logi',
        breadcrumbs: "Zarządzanie > Pobierz logi",
        link: '/Zarzadzanie',
    }, 
    {
        title: 'Włącz tryb developerski',
        breadcrumbs: "Zarządzanie > Włącz tryb developerski",
        link: '/Zarzadzanie',
    }, 
    {
        title: 'Lista dochódów',
        breadcrumbs: "Zarządzanie > Lista dochódów",
        link: '/Zarzadzanie',
    }, 
    {
        title: 'Dodaj dochód',
        breadcrumbs: "Zarządzanie > Dodaj dochód",
        link: '/Zarzadzanie',
    }, 
    {
        title: 'Usuń dochód',
        breadcrumbs: "Zarządzanie > Usuń dochód",
        link: '/Zarzadzanie',
    }, 
    {
        title: 'Wyłącz tryb developerski',
        breadcrumbs: "Zarządzanie > Wyłącz tryb developerski",
        link: '/Zarzadzanie',
    }, 
    {
        title: 'Tłumaczenie',
        breadcrumbs: "Tłumaczenie",
        link: '/Tlumaczenie',
    }, 
    {
        title: 'Tłumaczenie literałów',
        breadcrumbs: "Tłumaczenie > Tłumaczenie literałów",
        link: '/Tlumaczenie',
    }, 
    {
        title: 'Zarządzanie językami',
        breadcrumbs: "Tłumaczenie > Zarządzanie językami",
        link: '/Tlumaczenie',
    }, 
    {
        title: 'Wspierane języki',
        breadcrumbs: "Tłumaczenie > Zarządzanie językami > Wspierane języki",
        link: '/Tlumaczenie',
    }, 
    {
        title: 'Struktura literałów',
        breadcrumbs: "Tłumaczenie > Zarządzanie językami > Struktura literałów",
        link: '/Tlumaczenie',
    }, 
    {
        title: 'Treści na stronie',
        breadcrumbs: "CMS",
        link: '/CMS',
    }, 
    {
        title: 'CMS',
        breadcrumbs: "CMS",
        link: '/CMS',
    }, 
    {
        title: 'Zespół',
        breadcrumbs: "CMS > Zespół",
        link: '/CMS',
    }, 
    {
        title: 'Współprace',
        breadcrumbs: "CMS > Współprace",
        link: '/CMS',
    }, 
    {
        title: 'Galeria',
        breadcrumbs: "CMS > Galeria",
        link: '/CMS',
    }, 
    {
        title: 'Header',
        breadcrumbs: "CMS > Header",
        link: '/CMS',
    }, 
    {
        title: 'Inne zdjęcia',
        breadcrumbs: "CMS > Inne zdjęcia",
        link: '/CMS',
    }, 
    {
        title: 'Dodaj zdjęcie',
        breadcrumbs: "CMS > Inne zdjęcia",
        link: '/CMS',
    }, 
    {
        title: 'Zmień kolejność zdjęć',
        breadcrumbs: "CMS > Zmień kolejność",
        link: '/CMS',
    }, 
    {
        title: 'Aktualności',
        breadcrumbs: "Aktualności",
        link: '/Aktualnosci',
    }, 
    {
        title: 'Dodabj nową aktualność',
        breadcrumbs: "Aktualności > Dodabj nową aktualność",
        link: '/Aktualnosci',
    }, 
    {
        title: 'Usuń aktualność',
        breadcrumbs: "Aktualności > Usuń aktualność",
        link: '/Aktualnosci',
    }, 
    {
        title: 'Edytuj aktualność',
        breadcrumbs: "Aktualności > Edytuj",
        link: '/Aktualnosci',
    }, 
    {
        title: 'Dodaj wpis blogowy',
        breadcrumbs: "Aktualności > Dodabj nową aktualność",
        link: '/Aktualnosci',
    }, 
]

export default data