import create from 'zustand'
import { devtools } from 'zustand/middleware'
import data from './data/searchData'

const store = (set, get) => ({
    allLinks: [],
    searchResult: [],
    getSearchLinks: async () => {
        set(state => ({allLinks: data}))
    },
    searchLinks: async (searchValue) => {
        let result = await get().allLinks.filter(i => i.title.toLowerCase().includes(searchValue.toLowerCase()) === true && i)
        set(state => ({searchResult: result}))
    },
})


const searchStore = create(devtools(store))

export default searchStore