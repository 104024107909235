import React, {useState, useEffect} from 'react'
import { motion } from 'framer-motion'
import { searchItem, deleteForm } from '../../../../animations/animations'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faInfo} from '@fortawesome/free-solid-svg-icons'
import translatingStore from '../../../../zustand/translating'

const TranslateItem = ({item, languagesGlobal, translateFrom, translateTo}) => {
    let deleteTxtItem = translatingStore(state => state.deleteTxtItem)
    let getTxtItems = translatingStore(state => state.getTxtItems)
    let updateTxtItem = translatingStore(state => state.updateTxtItem)
    let {itemCategory, itemId, itemTxt, languages} = item
    let [originalLang, setOriginalValue] = useState("")
    let [translateValue, setTranslateValue] = useState("")
    let [isBackpanelOpen, setIsBackpanelOpen] = useState(false)
    let [delForm, setDelForm] = useState(false)
    let [delFormInput, setDelFormInput] = useState("")
    let [delFormError, setDelFormError] = useState("Aby usunąć język wpisz jego nazwe")
    useEffect(() => {
        setOriginalValue(languagesGlobal.filter(i => i.name === translateFrom.value ? i : null)[0]._id)
        setTranslateValue(languages[languagesGlobal.filter(i => i.name === translateTo.value ? i : null)[0]._id])
    }, [item])

    const prevEnter = (e) => {
        if(e.keyCode === 13){
          e.preventDefault()
        }
      }

    const handleDeleteTxtItem = () => {
        if(delFormInput === itemId){
            deleteTxtItem(item, setDelFormError, setIsBackpanelOpen, getTxtItems)
        }else{
            setDelFormError('Nazwy się nie pokrywają')
        }
    }



    useEffect(() => {
        setDelFormError("")
    }, [delFormInput])

    return (
        <><motion.div  key={item._id} variants={searchItem} initial="start" animate="end" class="w-full flex flex-col sm:flex-row justify-between items-center my-4">
            <textarea class="w-full sm:w-2/5 bg-gray-200 outline-none py-6 rounded px-2 placeholder-black resize-none" 
            value={languages[originalLang] ? languages[originalLang] : `Brak tłumaczenia na język ${translateFrom.value}`} disabled  type="text" maxLength={400} />
            <textarea class="w-full sm:w-2/5 my-4 sm:my-0 bg-gray-200 outline-none py-6 rounded px-2 placeholder-black resize-none"  placeholder="Wpisz nową treść literału"
            value={translateValue} onChange={(e) => setTranslateValue(e.target.value)}  onKeyDown={prevEnter} type="text" maxLength={400} />
            <div class="flex flex-row"> 
                <div  onClick={() => setIsBackpanelOpen(true)} class="h-10 mr-4 aspect-square rounded-full flex items-center justify-center bg-gray-300 cursor-pointer">
                    <FontAwesomeIcon icon={faInfo} />
                </div> 
                <div onClick={() => updateTxtItem(item, translateValue, languagesGlobal.filter(i => i.name === translateTo.value ? i : null)[0]._id, getTxtItems)} class="h-10 aspect-square rounded-full flex items-center justify-center bg-gray-300 cursor-pointer">
                    <FontAwesomeIcon icon={faSave} />
                </div> 
            </div>
        </motion.div>
        {isBackpanelOpen && <div  class="fixed w-screen h-screen left-0 top-0 flex justify-center items-center" style={{zIndex: 4}}>
        <motion.div onClick={() => setIsBackpanelOpen(false)} initial={{opacity: 0}} animate={{opacity: 1}} class="fixed left-0 top-0 w-full h-full cursor-pointer" 
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}></motion.div>
        <motion.div initial={{scale: 0.8}} animate={{scale: 1}} class="cursor-auto w-[90%] sm:w-2/3 lg:w-1/3 bg-gray-100 rounded p-6 flex flex-col items-center" 
        style={{zIndex: 5}}>
            <h2 class="w-full text-center font-bold text-xl">Informacje o literale:</h2>
            <p class="w-full lg:w-3/4 text-left text-sm mt-4 ml-2 text-gray-500">Indywidualny identyfikator</p>
            <input required class="w-full lg:w-3/4 mb-4 cursor-pointer bg-gray-200 outline-none py-6 rounded px-2 placeholder-black"
            value={itemId} disabled type="text" maxLength={40} />
            <p class="w-full lg:w-3/4 text-left text-sm mt-4 ml-2 text-gray-500">Kategoria</p>
            <input required class="w-full lg:w-3/4 mb-4 cursor-pointer bg-gray-200 outline-none py-6 rounded px-2 placeholder-black"
            value={itemCategory} disabled type="text" maxLength={40} />
            <p class="w-full lg:w-3/4 text-left text-sm mt-4 ml-2 text-gray-500">Domyślna treść </p>
            <textarea required class="w-full lg:w-3/4 h-[30vh] resize-none mb-4 cursor-pointer bg-gray-200 outline-none py-6 rounded px-2 placeholder-black"
            value={itemTxt} disabled  onKeyDown={prevEnter} type="text" maxLength={400} />
            <div class="w-full lg:w-3/4 mt-4 flex justify-between items-center">
                <h2 class="text-xl">Usunać literał?</h2>
                {!delForm ? 
                    <span onClick={() => setDelForm(true)} class="h-4 ml-4 cursor-pointer text-red-400 font-bold" >Usuń</span> :
                    <span onClick={() => setDelForm(false)} class="h-4 ml-4 cursor-pointer text-green-400 font-bold" >Anuluj</span>
                }
             </div>
            <motion.div class="w-full lg:w-3/4 flex flex-col " variants={deleteForm} initial="start" animate={!delForm ? 'start' : 'end'}>
                <div class="w-full mt-4 flex justify-between items-center"> 
                    <input maxLength={30} class=" outline-none bg-transparent border-b-2 w-2/3 border-solid border-black"  placeholder="Wpisz id literału" 
                    value={delFormInput} onChange={(e) => setDelFormInput(e.target.value)} />
                    <span onClick={handleDeleteTxtItem} class="h-4 ml-4 cursor-pointer text-red-400 font-bold" >Usuń</span>
                </div>
                <p class="text-sm h-6 text-red-400 mt-2">{delFormError}</p>
            </motion.div>            
        </motion.div>
        </div>}</>
    )
}

export default TranslateItem