import create from 'zustand'
import { devtools } from 'zustand/middleware'
import * as api from '../api/index'

const store = (set, get) => ({
    daysChart: [],
    countriesChart: [],
    isSiteBlocked: false,
    getDashboardData: async (datePanel) => {
        try{
            let startDate
            if(datePanel[0].startDate.getMonth()+1 < 10){
                startDate = `${datePanel[0].startDate.getFullYear()}-0${datePanel[0].startDate.getMonth()+1}-${datePanel[0].startDate.getDate()}`
            }else{
                startDate = `${datePanel[0].startDate.getFullYear()}-${datePanel[0].startDate.getMonth()+1}-${datePanel[0].startDate.getDate()}`
            }
            let endDate
            if(datePanel[0].startDate.getMonth()+1 < 10){
                endDate = `${datePanel[0].endDate.getFullYear()}-0${datePanel[0].endDate.getMonth()+1}-${datePanel[0].endDate.getDate()}`
            }else{
                endDate = `${datePanel[0].endDate.getFullYear()}-${datePanel[0].endDate.getMonth()+1}-${datePanel[0].endDate.getDate()}`
            }
            const result = await api.getDashboardData(startDate, endDate)
            set(state => ({daysChart: result.data.days, countriesChart: result.data.countries}))

        }catch(err){
            console.log(err)
        }
    },
    runScrapeScripts: async () => {
        try{
            api.runScrapeScripts()
        }catch(err){
            console.log(err)
        }
    },
    blockSite: async (authCode, setErrorMsg, setIsBackpanelOpen) => {
        try{
            let code = await authCode.join('').toUpperCase()
            let result = await api.blockSite([code])
            if(result.status === 200){
                setIsBackpanelOpen(false)
                set(state => ({isSiteBlocked: result.data.isBlocked}))
            }else{
                setErrorMsg('Something went wrong')
            }
        }catch(err){
            if(err.response.status === 403){
                setErrorMsg('Wrong auth code')
            }else{
                setErrorMsg('Something went wrong')
            }
            console.log(err)
        }
    },
    getIsSiteBlocked: async () => {
        try{
            const result = await api.getIsSiteBlocked()
            set(state => ({isSiteBlocked: result.data.isBlocked}))
            // set(state => ({isSiteBlocked: true}))
        }catch(err){
            console.log(err)
        }
    },
    getLogs: async (recipient) => {
        try{
            const result = await api.getLogs(recipient)
            console.log(result)
        }catch(err){
            console.log(err)
        }
    },
    restartApi: async () => {
        try{
            await api.restartApi()
        }catch(err){
            console.log(err)
        }
    },
    openSite: async () => {
        try{
            const result = await api.getSiteLoginLink()
            window.open(`https://corignis.org/workInProgress?login=${result.data.code}`, '_blank')
        }catch(err){
            console.log(err)
        }
    }
})


const dashboardStore = create(devtools(store))

export default dashboardStore