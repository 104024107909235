import React, {useState, useEffect} from 'react'
import {motion, AnimatePresence} from 'framer-motion'
import {cmsItem, editEndBtn} from '../../../../animations/animations'
import { faTrash, faCopy, faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {orderChanged} from '../../../../animations/animations'
import cmsStore from '../../../../zustand/cms'
import CmsForm from '../CmsForm/CmsForm.js'

const CmsItem = ({item, isEditingOrder}) => {
  const [isTextCopied, setIsTextCopied] = useState(false)
  const deleteCmsItem = cmsStore(state => state.deleteCmsItem)
  const [isBackpanelOpen, setIsBackpanelOpen] = useState(false)

  const handleCopy = () => {
    navigator.clipboard.writeText(item?.img)
    setIsTextCopied(true)
    setTimeout(() => {setIsTextCopied(false)}, 1000)
  }

  return (
    <>
      <motion.div variants={cmsItem} initial="start" animate="end" class="w-full h-full py-2 px-4 bg-gray-200 flex justify-between items-center rounded " >
        <div class="h-full flex justify-start items-center"> 
          <div class="h-full w-12">
            <img src={item.img} class="h-full w-full"/>
          </div>
          <p class="ml-4 font-bold">{item.name['6416fa0870e6b1d62be24c75']}</p>
        </div>
        <AnimatePresence>{!isEditingOrder && <motion.div variants={editEndBtn} initial="start" animate="end" exit="start"
         class="h-full sm:mr-8 text-xl flex justify-around items-center"> 
          <div onClick={item.type === "Inne zdjęcia" ? handleCopy : () => setIsBackpanelOpen(item)}
           class="h-4/5 mr-2 px-2 rounded-full flex items-center justify-center transition bg-gray-300 hover:bg-black hover:text-white cursor-pointer">
            <span class="mr-2 text-lg">{item.type === "Inne zdjęcia" ? "Skopiuj" : "Edytuj"}</span> 
            <FontAwesomeIcon icon={item.type === "Inne zdjęcia" ? faCopy : faEdit} />
          </div>
          <div onClick={() => deleteCmsItem(item)} 
          class="h-4/5 aspect-square rounded-full flex items-center justify-center bg-gray-300 hover:bg-black hover:text-white cursor-pointer">
            <FontAwesomeIcon icon={faTrash} />
          </div>
        </motion.div>}</AnimatePresence>
      </motion.div>
    <AnimatePresence>{isTextCopied && <motion.div class="fixed left-0 w-full flex justify-center z-[3]"
      variants={orderChanged} initial="start" animate="end" exit="start">
          <div class="w-3/5 p-4 bg-gray-300 rounded flex justify-center items-center text-lg">Skopiowano link do zdjęcia</div>
      </motion.div>}</AnimatePresence>
      {isBackpanelOpen && <div class="fixed w-screen h-screen left-0 top-0 flex justify-center items-center" style={{zIndex: 4}}>
        <motion.div onClick={() => setIsBackpanelOpen(false)} initial={{opacity: 0}} animate={{opacity: 1}} class="fixed left-0 top-0 w-full h-full cursor-pointer" 
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}></motion.div>
        <motion.div initial={{scale: 0.8}} animate={{scale: 1}} style={{zIndex: 5}}
        class="cursor-auto w-[90%] sm:w-2/3 md:w-1/2 lg:w-1/3 bg-gray-100 rounded p-6 flex flex-col items-center" >
            <CmsForm type={item.type} item={item} setIsBackpanelOpen={setIsBackpanelOpen}/>
        </motion.div>
      </div>}
    </>
  )
}

export default CmsItem