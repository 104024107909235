import React, {useState, useEffect} from 'react'
import optionsStore from '../../../zustand/options'
import {motion} from 'framer-motion'
import { textPopup } from '../../../animations/animations'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faX } from '@fortawesome/free-solid-svg-icons'
import searchStore from '../../../zustand/search'
import SearchItem from './SearchItem/SearchItem'

const SearchWindow = ({setIsSearchOpen}) => {
  const setIsBackpanelOpen = optionsStore(state => state.setIsBackpanelOpen)
  const searchResult = searchStore(state => state.searchResult)
  const searchLinks = searchStore(state => state.searchLinks)


  const handleBackpanelClick = () => {
    setIsSearchOpen(false)
    setIsBackpanelOpen()
  }

  const [searchValue, setSearcValue] = useState("")

  useEffect(() => {
    searchLinks(searchValue)
  }, [searchValue])

  return (
    <div id="SearchWindow" class="fixed w-screen flex justify-center items-center	h-screen left-0 top-0" style={{zIndex: 4}}>
      <motion.div onClick={handleBackpanelClick} id='SearchBackPanel' class="absolute left-0 top-0 w-full h-full cursor-pointer"
      initial={{opacity: 0}} animate={{opacity: 1}} style={{backgroundColor: 'rgba(0, 0, 0, 0.6)', zIndex: 5}}></motion.div>
      <div  class='cursor-auto flex flex-col h-4/5 w-5/6 md:w-3/5 xl:w-2/5 bg-gray-100 rounded p-6'
       style={{ zIndex: 5}}>
        <div id="SearchWindow_Headline" class="text-2xl w-full h-10 flex justify-between items-center ">
          <span class="mr-2">
            <FontAwesomeIcon icon={faSearch} />
          </span>
          <input autoFocus  value={searchValue} onChange={(e) => setSearcValue(e.target.value)} type='text'
          placeholder='Wyszukaj...' class="w-5/6 h-full outline-none border-none bg-inherit" />
          <div onClick={handleBackpanelClick} class="h-full flex items-center justify-center aspect-square rounded
          cursor-pointer bg-gray-200" >
            <FontAwesomeIcon icon={faX} />
          </div>
        </div>
        <div class="my-3 border-solid border-b-2 border-gray-200"></div>
        <div id="SearchWindow_content" class="grow overflow-y-auto">
            {searchValue === "" ? 
            <div class="w-full h-full flex justify-center items-center text-center">
              <motion.h1 variants={textPopup} initial="start" animate="end" class="text-2xl md:text-4xl text-gray-400">Zacznij pisać ...</motion.h1>
            </div> :
            <>{searchResult.length > 0 ? <>{
                searchResult.map((item, i) => <SearchItem close={handleBackpanelClick} item={item} key={`SearchItem${i}`}/>)
            }</>:
              <div class="w-full h-full flex justify-center items-center ">
                <motion.h1 variants={textPopup} initial="start" animate="end" class="text-center text-2xl md:text-4xl text-gray-400">Twoje hasło "{searchValue}" nie pasuje do żadnego wyniku</motion.h1>
              </div>
            }</>
            }
        </div>
      </div>
    </div>
  )
}

export default SearchWindow