import React, {useState, useEffect} from 'react'
import {motion, AnimatePresence} from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faCheck, faArrowsUpDown, faPlus, faEdit, faEye, faEyeSlash, faX } from '@fortawesome/free-solid-svg-icons'
import { notifItem, editBtn, editEndBtn, newsItem, newsItemContent, newsItemArrows } from '../../../../animations/animations'
import {Draggable} from 'react-beautiful-dnd'
import newsStore from '../../../../zustand/news'
import optionsStore from '../../../../zustand/options'
import NewsItemForm from './NewsItemForm/NewsItemForm'
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';

const NewsItem = ({item, newItem, index, language}) => {
const [isEditing, setIsEditing] = useState(false)

const setIsPublic = newsStore(state => state.setIsPublic)
const addNews = newsStore(state => state.addNews)
const getNews = newsStore(state => state.getNews)
const uptadeNews = newsStore(state => state.uptadeNews)
const deleteNews = newsStore(state => state.deleteNews)
const isNewsEditing = optionsStore(state => state.isNewsEditing)
const setIsNewsEditing = optionsStore(state => state.setIsNewsEditing)

const handleChangeEditing = (value) =>{ 
    setIsEditing(value)
    if(value === true){
        setIsNewsEditing(1)
    }else{
        setIsNewsEditing(-1)
    }
}

const [isImgOk1, setIsImgOk1] = useState(newItem ? false : true)
const [isImgOk2, setIsImgOk2] = useState(newItem ? false : true)

const defaultForm = {
    language: item ? item.language : language,
    title: item ? item.title : "",
    description: item ? item.description :  "",
    img: item ? item.img : "",
    promote: item ? item.promote : "nothing",
    promoteValue: item ? item.promoteValue : {
        text: "",
        counter: {
            before: "",
            value: 0,
            after: "",
        },
        fundraising: "",
        icons: [],
    },
    type: item ? item.type : "static",
    typeValue:  {
        link: item ? item.typeValue.link : "",
        blogImg: item ? item.typeValue.blogImg : "",
        blogConverted: item ? item.typeValue.blogConverted : {}
    },
    expandable: item ? item.expandable : true,
}

const [formValues, setFormValues] = useState(defaultForm)
const [isImgsOk, setIsImgsOk] = useState(false)

useEffect(() => {
    setFormValues(defaultForm)
    setIsImgOk1(newItem ? false : true)
    setIsImgOk2(newItem ? false : true)
}, [item, isEditing])

useEffect(() => {
    if(newItem){
        setFormValues({...formValues, language: language})
    }
}, [language])

useEffect(() => {
    if(isImgOk1){
        if(formValues.type === 'blog'){
            if(isImgOk2){
                setIsImgsOk(true)
            }else{
                setIsImgsOk(false)
            }
        }else{
            setIsImgsOk(true)
        }
    }else{
        setIsImgsOk(false)
    }
}, [isImgOk1, isImgOk2, formValues.type])


const handleSubmit = async (e) => {
    e.preventDefault()
    if(isImgsOk){
        if(formValues.title !== "" && formValues.description !== "" && formValues.img !== "" && formValues.language.value !== null){
            if(newItem){
                await addNews(formValues)
                setIsEditing(false)
                await setFormValues(defaultForm)
                await setIsImgOk1(newItem ? false : true)
                await setIsImgOk2(newItem ? false : true)
                await getNews()
            }else{
                await uptadeNews(item._id, formValues, handleChangeEditing)
                await setIsImgOk1(newItem ? false : true)
                await setIsImgOk2(newItem ? false : true)
                await getNews()
            }
        }
    }
}


  return (
    <>{newItem ? 
        <form class="w-full mt-6 overflow-hidden bg-gray-200 rounded" onSubmit={handleSubmit} >
            <div class="w-full h-20 flex justify-between items-center bg-gray-200 z-[2] relative" >
                <div class="w-4/5 h-full flex items-center text-2xl  " >
                    <div class="w-4 h-full flex justify-center items-center">
                    </div>
                    <p class="whitespace-nowrap ml-4">Dodaj nową aktualność</p>
                </div>
                <div class="w-64 h-full flex justify-around items-center text-xl" >
                    <div class="w-2/3 h-full flex justify-around items-center relative">
                        <AnimatePresence>{isEditing && <div class="w-full h-full flex justify-around items-center absolute">
                            <motion.button type="submit" variants={editEndBtn} initial="start" animate="end" exit="start"
                            class="h-3/5 aspect-square rounded-full flex items-center justify-center bg-gray-300 hover:bg-black hover:text-white cursor-pointer ">
                                <FontAwesomeIcon icon={faCheck} />
                            </motion.button>
                            <motion.div onClick={() => setIsEditing(false)} variants={editEndBtn} initial="start" animate="end" exit="start"
                            class="h-3/5 aspect-square rounded-full flex items-center justify-center bg-gray-300 hover:bg-black hover:text-white cursor-pointer ">
                                <FontAwesomeIcon icon={faX} />
                            </motion.div>
                        </div>}</AnimatePresence>
                        <AnimatePresence>{!isEditing &&
                            <motion.div onClick={() => setIsEditing(true)} variants={editBtn} initial="start" animate="end" exit="start"
                            class="h-3/5 aspect-square rounded-full flex items-center justify-center bg-gray-300 hover:bg-black hover:text-white cursor-pointer absolute">
                                <FontAwesomeIcon icon={faPlus} />
                            </motion.div> 
                        }</AnimatePresence>
                    </div>
                </div>
            </div>
            <AnimatePresence>{isEditing && 
            <motion.div class="w-full" variants={newsItem} initial="start" animate="end" exit="start">
               <motion.div class="w-full flex justify-center pb-4 px-4" variants={newsItemContent} initial="start" animate="end" exit="start"> 
                    <NewsItemForm formValues={formValues} setFormValues={setFormValues} isImgsOk={isImgsOk}  
                    isImgOk1={isImgOk1} isImgOk2={isImgOk2} setIsImgOk1={setIsImgOk1} setIsImgOk2={setIsImgOk2}/>
                </motion.div>
            </motion.div>}</AnimatePresence>
        </form>:
        <Draggable key={`item${index}`} draggableId={`item${index}`} index={index}>
        {(provided) => (
            <form {...provided.draggableProps}  ref={provided.innerRef} onSubmit={handleSubmit}
            class="w-full mt-6 overflow-hidden bg-gray-200 rounded">
                <div class="w-full h-20 flex justify-between items-center bg-gray-200 z-[2] relative" >
                <div class="ml-4 w-4/5 h-full flex items-center text-2xl  " >
                    <div class="w-4 h-full" >
                        <AnimatePresence>{isNewsEditing === 0 && <div {...provided.dragHandleProps} class="w-full h-full flex justify-center items-center">
                            <motion.div variants={newsItemArrows} initial="start" animate="end" exit="start" >
                                <FontAwesomeIcon icon={faArrowsUpDown} />
                            </motion.div>
                        </div>}</AnimatePresence>
                    </div>
                    <p class="whitespace-nowrap ml-4">{item.title}</p>
                </div>
                <div class="w-64 h-full flex justify-around items-center text-xl" >
                    <div class="w-3/4 h-full flex justify-around items-center relative">
                        <AnimatePresence>{isEditing && <div class="w-full h-full flex justify-around items-center absolute">
                        <motion.button type="submit" variants={editEndBtn} initial="start" animate="end" exit="start"
                            class="h-3/5 aspect-square rounded-full flex items-center justify-center bg-gray-300 hover:bg-black hover:text-white cursor-pointer ">
                                <FontAwesomeIcon icon={faCheck} />
                            </motion.button>
                            <motion.div onClick={() => handleChangeEditing(false)} variants={editEndBtn} initial="start" animate="end" exit="start"
                            class="h-3/5 aspect-square rounded-full flex items-center justify-center bg-gray-300 hover:bg-black hover:text-white cursor-pointer ">
                                <FontAwesomeIcon icon={faX} />
                            </motion.div>
                            <motion.div onClick={() => deleteNews(item, handleChangeEditing)} variants={editEndBtn} initial="start" animate="end" exit="start"
                            class="h-3/5 aspect-square rounded-full flex items-center justify-center bg-gray-300 hover:bg-black hover:text-white cursor-pointer ">
                                <FontAwesomeIcon icon={faTrash} />
                            </motion.div>
                        </div>}</AnimatePresence>
                        <AnimatePresence>{!isEditing &&
                            <motion.div onClick={() => handleChangeEditing(true)} variants={editBtn} initial="start" animate="end" exit="start"
                            class="h-3/5 aspect-square rounded-full flex items-center justify-center bg-gray-300 hover:bg-black hover:text-white cursor-pointer absolute">
                                <FontAwesomeIcon icon={faEdit} />
                            </motion.div> 
                        }</AnimatePresence>
                    </div>
                    <div class="w-1/4 h-full flex justify-around items-center">
                    <div onClick={() => setIsPublic(item)} class="h-3/5 aspect-square rounded-full flex items-center justify-center bg-gray-300 hover:bg-black hover:text-white cursor-pointer">
                        <FontAwesomeIcon icon={item.isPublic ? faEye : faEyeSlash} />
                    </div> 
                </div>
                </div>
            </div>
            <AnimatePresence>{isEditing && 
            <motion.div class="w-full" variants={newsItem} initial="start" animate="end" exit="start">
               <motion.div class="w-full flex flex-col items-center pb-4 px-4" variants={newsItemContent} initial="start" animate="end" exit="start"> 
                    <NewsItemForm formValues={formValues} setFormValues={setFormValues} isImgsOk={isImgsOk}  
                    isImgOk1={isImgOk1} isImgOk2={isImgOk2} setIsImgOk1={setIsImgOk1} setIsImgOk2={setIsImgOk2}/>
                </motion.div>
            </motion.div>}</AnimatePresence>
            </form>
        )}
        </Draggable>
     }</>)
}

export default NewsItem