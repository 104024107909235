import create from 'zustand'
import { devtools } from 'zustand/middleware'
import * as api from '../api/index'

const store = (set, get) => ({
    isNavOpen: window.innerWidth > 1280 ? true : false,
    windowSize: 1500,
    isBackpanelOpen: false,
    leftPx: 0,
    isDevMode: false,
    isNewsEditing: 0,
    setIsNavOpen: () => {
        set(state => ({leftPx: state.isNavOpen ? 0 : 300}))
        set(state => ({isNavOpen: !state.isNavOpen}))
    },
    checkWindowSize: () => {
        set((state) => ({windowSize: window.innerWidth}))
    },
    setLeftPx: (px) => {
        set(state => ({leftPx: px}))
    },
    setIsBackpanelOpen: () => {
        set(state => ({isBackpanelOpen: !state.isBackpanelOpen}))
    }, 
    setIsNewsEditing: (value) => {
        set(state => ({isNewsEditing: state.isNewsEditing + value}))
    }, 
    sendAuthRequest: async (setErrorMsg, setIsAuthSend, name, email) => {
        try{
            let recipient = {name, email}
            let result = await api.sendAuth(recipient)
            if(result.status === 200){
                setIsAuthSend(true)
            }else{
                setErrorMsg('Something went wrong')
            }
        }catch(err){
            setErrorMsg('Something went wrong')
        }
    },
    verifyAuthCode: async (authCode, setErrorMsg, endFunc, setIsBackpanelOpen) => {
        try{
            let code = await authCode.join('').toUpperCase()
            let result = await api.verifyAuth([code])
            if(result.status === 200){
                setIsBackpanelOpen(false)
                endFunc()
            }else{
                setErrorMsg('Something went wrong')
            }
        }catch(err){
            setErrorMsg('Something went wrong')
        }
    },
    toggleDevMode: () => {
        set(state => ({isDevMode: !state.isDevMode}))
    }
    
})


const optionsStore = create(devtools(store))

export default optionsStore