import React, {useState, useEffect} from 'react'
import documentsStore from '../../../../zustand/documents'
import translatingStore from '../../../../zustand/translating'
import Select from 'react-select';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';

const DocumentsForm = ({type, setIsBackpanelOpen, item}) => {
    const languages = translatingStore(state => state.languages)
    const [langOption, setLangOption] = useState({value: languages[0]?._id, label: languages[0]?.name})
    const [langOptions, setLangOptions] = useState([])
    const addDocsItem = documentsStore(state => state.addDocsItem)
    const updateDocsItem = documentsStore(state => state.updateDocsItem)

    const [newItem, setNewItem] = useState({
        type: type,
        name: item ? item.name : {},
        desc: {},
    })

    
    useEffect(() => {
        let arr = []
        languages?.forEach(i => {
            arr.push({value: i?._id, label: i?.name})
        })
        setLangOptions(arr)

        if(item){
            let newDesc = {...item.desc}
            Object.keys(newDesc).forEach(i => {
                newDesc[i].state = EditorState.createWithContent(convertFromRaw(JSON.parse(newDesc[i].converted)))
            })
            setNewItem({...newItem, desc: newDesc})
        }
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()
        if(item){
            let newDesc = {...newItem.desc}
            Object.keys(newDesc).forEach(i => {
                newDesc[i].state = {}
            })
            updateDocsItem(item._id, {...newItem, desc: newDesc})
        }else{
            let newDesc = {...newItem.desc}
            Object.keys(newDesc).forEach(i => {
                newDesc[i].state = {}
            })
            addDocsItem({...newItem, desc: newDesc})
        }
        
        setIsBackpanelOpen(false)
    }

    const handleEditorChange = async (editorState) => {
        let content = await editorState.getCurrentContent()
        let convertedContent = await convertToRaw(content)
        setNewItem({...newItem, desc: {...newItem.desc, [langOption.value]: {state: editorState, converted: JSON.stringify(convertedContent)}}})
    }

    return (
        <form onSubmit={handleSubmit} class="w-full lg:w-3/4 flex flex-col items-center">
            {!item && <h2 class="w-full text-center font-bold text-xl">Dodaj nowy element</h2>}
            <Select options={langOptions} value={langOption}  
                onChange={setLangOption} styles={{
                  container: (baseStyles, state) => ({
                    ...baseStyles,
                    width: '100%',
                    margin: '1rem 0',
                    cursor: 'pointer',
                  }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    padding: '0 0.5rem',
                    height: '3.5rem',
                    background: 'rgb(229 231 235)',
                    border: 'none',
                    outline: 'none',
                    boxShadow: 'none',
                    cursor: 'pointer',
                  }),
            }}/>
            <input required class="w-full my-4 cursor-pointer bg-gray-200 outline-none h-14 rounded px-2 placeholder-black"  
            placeholder="Tytuł rozdziału" type="text" maxLength={100} 
            value={newItem.name[langOption.value] ? newItem.name[langOption.value] : ""} 
            onChange={(e) => setNewItem({...newItem, name: {...newItem.name, [langOption.value]: e.target.value}})} />
            <Editor 
                editorState={newItem.desc[langOption.value]?.state ? newItem.desc[langOption.value]?.state : EditorState.createEmpty()}
                toolbarClassName="h-1/4"
                wrapperClassName="bg-white h-96 w-full rounded"
                editorClassName="bg-white h-3/4 p-2"
                onEditorStateChange={handleEditorChange}
                toolbar={{
                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'colorPicker', 'image', 'link', 'list', 'textAlign', 'history'],
                    inline: {
                        options: ['bold', 'italic', 'underline', 'strikethrough'],
                    },
                }}
            />           
            <button type='submit' class="w-full mt-6 text-center bg-black text-white cursor-pointer p-2 rounded hover:bg-gray-800 transition">Zapisz</button>
        </form>
    )
}

export default DocumentsForm